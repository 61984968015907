import { Routes, Route, useLocation, Navigate, } from "react-router-dom";
import { DashboardLeadsPeoplePage } from "./pages/dashboardLeadsPeoplePage/DashboardLeadsPeoplePage";
import { useEffect } from "react";
import React from "react";
import { LayoutApp } from "./components/layouts/LayoutApp";
import { useSidebarActions } from "./utils/redux/storeHooks/actionsHooks/useSidebarActions";
import { TableLeads } from "./components/tableLeads/TableLeads";
import { TeamManagementGuard } from "./route-guard/route-guard-teamManagement";
import { NewFeedPage } from "./pages/newFeedPage/newFeedPage";
import { DailyFeedTab } from "./pages/botManagementPage/features/tabs/dailyFeedTab";
import { InitialFeedTab } from "./pages/botManagementPage/features/tabs/initialFeedTab";
import { LeadsTab } from "./pages/botManagementPage/features/tabs/leadsTab";
import SignIn from "./pages/authentication/signIn/signIn";
import RestorePassword from "./pages/authentication/restore_password/restore_password";
import { excludePathesToRequest } from "./utils/general/excludePathesToRequestInAppComponent";
import { TableUsers } from "./pages/adminPage/features/tableUsers/TableUsers";
import { TableTeams } from "./pages/adminPage/features/tableTeams/TableTeams";
import { BotManagementGuard } from "./route-guard/route-guard-botManagement";
import { AdminPageGuard } from "./route-guard/route-guard-AdminPage";
import { NotAllowedPage } from "./pages/notAllowedPage/NotAllowedPage";
import { AutomationPage } from "./pages/automationPage/AutomationPage";
import { PromptSettings } from "./pages/automationPage/features/promptSettings/PromptSettings";
import AutomationSettings from "./pages/automationPage/features/automationSettings/AutomationSettings";
import { AccountDetails } from "./pages/accountDetails/AccountDetails";
import { UsersStatistic } from "./pages/usersStatistic/UsersStatistic";

function App() {
  const pathname = useLocation().pathname;
  const { checkValidCookie } = useSidebarActions()

  useEffect(() => {
    const isPathExcluded = excludePathesToRequest.some(excludePath => {
      if (excludePath instanceof RegExp) {
        return excludePath.test(pathname);
      }
      return excludePath === pathname;
    });

    if (!isPathExcluded) {
      checkValidCookie();
    }
  }, [pathname, checkValidCookie])


  return (
    <Routes>
      <Route path="SignIn" element={<SignIn />} />
      <Route path="restore-pass" element={<RestorePassword />} />
      {/* <Route path="prompts-manager" element={<PromptSettingPage />} /> */}
      <Route path="bot-management" element={<BotManagementGuard />} >
        <Route index element={<DailyFeedTab />} />
        <Route path="/bot-management/initial-feed" element={<InitialFeedTab />} />
        <Route path="/bot-management/leads" element={<LeadsTab />} />
      </Route>
      <Route path="admin-page" element={<AdminPageGuard />} >
        <Route path="/admin-page/users" element={<TableUsers />} />
        <Route path="/admin-page/teams" element={<TableTeams />} />
      </Route>
      <Route path="/" element={<LayoutApp />}>
        <Route index element={<Navigate to="overview" />} />
        <Route path="/overview" element={<UsersStatistic />} />
        <Route path="team-management" element={<TeamManagementGuard />} />
        <Route path="leads" element={<DashboardLeadsPeoplePage />} >
          <Route index element={<TableLeads />} />
        </Route>
        <Route path="feed" element={<Navigate to="/feed/general" replace />} />
        <Route path="feed/:tabId" element={<NewFeedPage />} />
        <Route path="settings" element={<AutomationPage />} >
          <Route path="/settings/prompts" element={<Navigate to="/settings/prompts/default" replace />} />
          <Route path="/settings/prompts/:promptLink" element={<PromptSettings />} />
          <Route path="/settings/automation" element={<AutomationSettings />} />
        </Route>
        <Route path="/account-details" element={<AccountDetails />} />
      </Route>
      <Route path="*" element={<NotAllowedPage />} />
    </Routes>
  );
}

export default App;
