import { styled, TextField } from "@mui/material";

export const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    height: '44px',
    borderRadius: '1px',
    fontFamily: 'Kanit',
    margin: 0,

    '&:hover fieldset': {
      borderColor: 'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'rgba(109, 48, 183, 1)',
      boxShadow: 'rgba(109, 48, 183, 1) 0px 1px 3px'
    },
    '& .MuiInputBase-input': {
      caretColor: 'rgba(109, 48, 183, 1)',
    },
  },
})

export const StyledTextFieldWithError = styled(TextField)({
  position: 'relative', // Required for positioning the asterisk
  '& .MuiOutlinedInput-root': {
    height: '44px',
    borderRadius: '1px',
    fontFamily: 'Kanit',
    margin: 0,
    '&:hover fieldset': {
      borderColor: 'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'rgba(109, 48, 183, 1)',
      boxShadow: 'rgba(109, 48, 183, 1) 0px 1px 3px',
    },
    '& .MuiInputBase-input': {
      caretColor: 'rgba(109, 48, 183, 1)',
    },
  },
  // Styles for the error state
  '& .MuiOutlinedInput-root.Mui-error fieldset': {
    borderColor: 'red',
    boxShadow: 'red 0px 1px 3px',
  },
  '& .MuiFormHelperText-root.Mui-error': {
    color: 'red',
  },
  '&::after': {
    content: '"*"',
    color: 'red',
    position: 'absolute',
    top: '-6px',
    right: '8px',
    fontSize: '24px',
    fontWeight: 'bold',
  },
});

