import { getRequest, postRequest, postRequestExport } from "../../../general/https";
import { checkErrorStatus } from "../../../general/checkErrorStatus";
import { createAsyncThunkCheckSubscription } from "../../middlewares/thunkCondition";
import { ExportStatisticResponse, GetGeneralStatisticResponse, GetStatisticPayload, StatisticForCampaignResponse } from "./types";
import { getUserInformationFromlocalStorage } from "../../../general/get_set_user_information";

export const getGeneralStatistic = createAsyncThunkCheckSubscription<GetGeneralStatisticResponse, GetStatisticPayload>(
  'usersStatistic/getGeneralStatistic',
   async (payload, {rejectWithValue}) => {
    try {
      const {id} = getUserInformationFromlocalStorage();
      const body = {
        ...payload,
        userId: id,
      }
      const response = await postRequest(`/users-statistic`, body)
      checkErrorStatus(response)
      return response.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }  
)

export const getCampaignStatistic = createAsyncThunkCheckSubscription<StatisticForCampaignResponse, GetStatisticPayload>(
  'usersStatistic/getCampaignStatistic',
  async (payload, {rejectWithValue}) => {
    const {id} = getUserInformationFromlocalStorage();
    const body = {
      ...payload,
      userId: id,
    }
    
    try {
      const response = await postRequest(`/users-statistic`, body)
      checkErrorStatus(response)
      return response.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const exportStatistic = createAsyncThunkCheckSubscription<ExportStatisticResponse, GetStatisticPayload>(
  'usersStatistic/exportStatistic',
  async (payload, {rejectWithValue}) => {
    const {id} = getUserInformationFromlocalStorage();
    const body = {
      ...payload,
      userId: id,
    }
    
    try {
      const response = await postRequestExport(`/users-statistic/export`, body)

      checkErrorStatus(response)
      console.log(response)

      const url = window.URL.createObjectURL(response.data);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'statistic.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      return response.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)