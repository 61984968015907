import { useMemo } from 'react';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useAppDispatch } from '../typedHooks';
import { tableTeamManagementActions } from '../../slices/tableTeamManagmentSlice/teamManagementSlice';
import { deleteTeamMemberAndUpdateTeamList, getTeamList } from '../../slices/tableTeamManagmentSlice/thunks';

const rootActions = {
  ...tableTeamManagementActions,
  getTeamList,
  deleteTeamMemberAndUpdateTeamList,
};

export const useTableTeamManagementActions = () => {
  const dispatch = useAppDispatch();
  return useMemo(() => bindActionCreators(rootActions, dispatch), [dispatch]);
};