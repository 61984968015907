export interface UsersStatisticResponseI {
  profileViews: number;
  leadsCount: number;
  leadsFirstConnectionCount: number;
  leadsSecondConnectionCount: number;
  commentsCount: number;
  repliesCount: number;
  likesCount: number;
  leadsReactionsCount: number;
  conversionRate: number;
}

export enum StatisticType {
  GENERAL = 'GENERAL',
  CAMPAIGN = 'CAMPAIGN',
}

export type CampaignDistributeArr = {
  name: string;
  value: number;
  percentage: number;
}

export enum DiagramsHeaderButtonType {
  comments = 'comments',
  replies = 'replies',
  likes = 'likes',
}