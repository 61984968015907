import * as React from 'react';
import style from '../../botManagementPage.module.scss'
import { Box, Typography } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import { useBotManagementData } from '../../../../utils/redux/storeHooks/dataHooks/useBotManagementData';
import { ChartName } from '../../../../types/pages/botManagmentPage/chartName';

type Props = {
  chartTitle: string;
  chartName: ChartName;
}

export const BotChart = ({ chartName, chartTitle }: Props) => {
  const { botStatisticForChart } = useBotManagementData()
  // console.log(botStatisticForChart)
  const botForChart = botStatisticForChart.map(bot => {
    switch (chartName) {
      case ChartName.countOfLeadsProcessedByParsingFeedForToday:
        return {
          name: bot.firstName + ' ' + bot.lastName,
          data: bot.countOfLeadsProcessedByParsingFeedForToday
        }
      case ChartName.countOfLeadsProcessedByParsingFeedForMonth:
        return {
          name: bot.firstName + ' ' + bot.lastName,
          data: bot.countOfLeadsProcessedByParsingFeedForMonth
        }
      case ChartName.countOfLeadsProcessedByParsingLeadsForToday:
        return {
          name: bot.firstName + ' ' + bot.lastName,
          data: bot.countOfLeadsProcessedByParsingLeadsForToday
        }
      case ChartName.countOfLeadsProcessedByParsingLeadsForMonth:
        return {
          name: bot.firstName + ' ' + bot.lastName,
          data: bot.countOfLeadsProcessedByParsingLeadsForMonth
        }
    }
  })
  const names = botForChart.map(bot => {
    return bot.name
  })
  const counts = botForChart.map(bot => {
    return bot.data
  })

  return (
    <Box className={style.botManagement__statistic_charts_chart}>
      <Typography className={style.botManagement__statistic_charts_chart_title}>
        {chartTitle}
      </Typography>
      <BarChart
        xAxis={[
          {
            id: 'barCategories',
            data: [...names],
            scaleType: 'band',
          },
        ]}
        series={[
          {
            data: [...counts],
            color: '#3B2470'
          },
        ]}
        width={500}
        height={400}
      />

    </Box>
  )
}

