import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { usersStatisticSliceInitialState } from './types';
import { exportStatistic, getCampaignStatistic, getGeneralStatistic } from './thunks';

const initialState: usersStatisticSliceInitialState = {
  isGeneralStatisticRequest: false,
  isCampaignStatisticRequest: false,
  isExportRequest: false,
  //general statistic data
  profileViews: 0,
  leadsCount: 0,
  leadsFirstConnectionCount: 30,
  leadsSecondConnectionCount: 70,
  commentsCount: 0,
  repliesCount: 0,
  likesCount: 0,
  leadsReactionsCount: 0,
  conversionRate: 0,
  pendingReactions: 0,
  leadsLimit: 0,
  //campaign statistic data
  commentsResponseArr: null,
  leadsLikeResponseArr: null,
  leadsRepliesResponseArr: null,
  currentDiagramsData: null,
  //custom users fields
  dateRange: {
    startDate: new Date(new Date().setDate(new Date().getDate() - 30)).toDateString(),
    endDate: new Date().toDateString(),
  },
};

export const usersStatisticSlice = createSlice({
  name: 'usersStatisticSlice',
  initialState,
  reducers: {
    setDateRange: (state, { payload }: PayloadAction<{startDate: string, endDate?: string | null}>) => {
      state.dateRange = payload;
    },
  },
  extraReducers: (builder) => {
    //Get general statistic data
    builder.addCase(getGeneralStatistic.pending, (state, action) => {
      state.isGeneralStatisticRequest = true;
    })
    builder.addCase(getGeneralStatistic.fulfilled, (state, action) => {
      const {
        leadsCount,
        commentsCount,
        repliesCount,
        likesCount,
        profileViews,
        conversionRate,
        leadsFirstConnectionCount,
        leadsSecondConnectionCount,
        leadsReactionsCount,
        pendingReactions,
        leadsLimit
      } = action.payload;
      state.isGeneralStatisticRequest = false;
      state.leadsCount = leadsCount;
      state.commentsCount = commentsCount;
      state.repliesCount = repliesCount;
      state.profileViews = profileViews;
      state.conversionRate = conversionRate;
      state.leadsFirstConnectionCount = leadsFirstConnectionCount;
      state.leadsSecondConnectionCount = leadsSecondConnectionCount;
      state.leadsReactionsCount = leadsReactionsCount;
      state.likesCount = likesCount;
      state.pendingReactions = pendingReactions
      state.leadsLimit = leadsLimit;
    })
    builder.addCase(getGeneralStatistic.rejected, (state, action) => {
      state.isGeneralStatisticRequest = false;
    })
    //Get campaign statistic data
    builder.addCase(getCampaignStatistic.pending, (state, action) => {
      state.isCampaignStatisticRequest = true;
    })
    builder.addCase(getCampaignStatistic.fulfilled, (state, action) => {
      const {commentsResponseArr, leadsLikeResponseArr, leadsRepliesResponseArr} = action.payload;
      state.isCampaignStatisticRequest = false;
      state.commentsResponseArr = commentsResponseArr;
      state.leadsLikeResponseArr = leadsLikeResponseArr;
      state.leadsRepliesResponseArr = leadsRepliesResponseArr;      
    }) 
    builder.addCase(getCampaignStatistic.rejected, (state, action) => {
      state.isCampaignStatisticRequest = false;
    })
    //Export statistic
    builder.addCase(exportStatistic.pending, (state, action) => {
      state.isExportRequest = true;
    })
    builder.addCase(exportStatistic.fulfilled, (state, action) => {
      state.isExportRequest = false;
    }) 
    builder.addCase(exportStatistic.rejected, (state, action) => {
      state.isExportRequest = false;
    })     
  }    
});

export const usersStatisticSliceActions = usersStatisticSlice.actions;
export const usersStatisticSliceReducer = usersStatisticSlice.reducer;
