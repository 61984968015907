import React, { useEffect } from 'react';
import { FunctionComponent } from "react";
import scss from "./Sidebar.module.scss";
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import ReplyOutlinedIcon from '@mui/icons-material/ReplyOutlined';
import FeedRoundedIcon from '@mui/icons-material/FeedRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import { NavLink, useLocation } from 'react-router-dom';
import { getUserInformationFromlocalStorage } from '../../utils/general/get_set_user_information';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { useSidebarActions } from '../../utils/redux/storeHooks/actionsHooks/useSidebarActions';
import { useSidebarData } from '../../utils/redux/storeHooks/dataHooks/useSidebarData';
import { Avatar, Badge, Box, Button, Divider, LinearProgress, Link, Stack, Typography } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import SettingsIcon from '@mui/icons-material/Settings';
import BadgeIcon from '@mui/icons-material/Badge';
import { usePopupActions } from '../../utils/redux/storeHooks/actionsHooks/usePopupActions';
import { usePopupData } from '../../utils/redux/storeHooks/dataHooks/usePopupData';
import { useAccountDetailsActions } from '../../utils/redux/storeHooks/actionsHooks/useAccountDetailsActions';
import { useAccountDetailsData } from '../../utils/redux/storeHooks/dataHooks/useAccountDetailsData';
import { Payment_status } from '../../types/PPG/Payment_status';
import { UserPermissionList } from '../../types/PPG/UserPermissionList';
import { AccessAppStatuses } from '../../types/PPG/AccessAppStatuses.enum';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';

export const Sidebar: FunctionComponent = () => {
  const userInformation = getUserInformationFromlocalStorage();

  const {
    setSideBarWide,
    getSideBarData
  } = useSidebarActions();
  const {
    totalCountOfLeads,
    pendingReplies,
    countOfRepliedFeed,
    checkOfValidCookie,
    replies,
    isDataLoading,
    checkingCookiesProcess,
    leadsLimit,
    isSideBarWide,
    profileViews
  } = useSidebarData();
  const { setPopupUpgradePlanOpen } = usePopupActions()
  const { isPopUpUpgradePlanOpen } = usePopupData();
  const { getSubscriptionDetails } = useAccountDetailsActions();
  const { activeSubscription, accessAppStatuses } = useAccountDetailsData();
  const pathname = useLocation().pathname


  const getSidebarData = async () => {
    await getSideBarData();
    getSubscriptionDetails({
      isUpdateMethod: false,
      isAccountDetailsPage: pathname === '/account-details'
    });
  }

  useEffect(() => {
    getSidebarData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  const activeLinkStyle = ({ isActive }: { isActive: boolean }) => {
    return {
      backgroundColor: isActive ? "#6D30B7" : "transparent"
    };
  }

  const activeFeedLinkStyle = ({ isActive }: { isActive: boolean }) => {
    if (pathname.includes('feed')) {
      return {
        backgroundColor: "#6D30B7"
      };
    }
    return {
      backgroundColor: "transparent"
    };
  }

  const activeSettingLinkStyle = ({ isActive }: { isActive: boolean }) => {
    if (pathname.includes('settings')) {
      return {
        backgroundColor: "#6D30B7"
      };
    }
    return {
      backgroundColor: "transparent"
    };
  }

  const toogleSideBar = () => {
    setSideBarWide(!isSideBarWide)
  }

  const toogleUpgradePlanPopUp = () => {
    setPopupUpgradePlanOpen(!isPopUpUpgradePlanOpen)
  }

  const isButtonUpgradePlanVisible = activeSubscription?.paymentStatus === Payment_status.CONFIRMED
    && activeSubscription.permission === UserPermissionList.ALLOW_APP_ACCESS
  const isToggleSideBarVisible = accessAppStatuses === AccessAppStatuses.SUBSCRIPTION_ACTIVE

  if (!isSideBarWide) {
    return (
      <Box className={scss.sidebarNarrow}>
        {isToggleSideBarVisible && <Box className={scss.buttonNarrow} onClick={toogleSideBar}>
          {
            isSideBarWide
              ? <ArrowBackIosNewIcon sx={{ color: '#191F2E', fontSize: 14, fontWeight: 800 }} />
              : <ArrowForwardIosIcon sx={{ color: '#191F2E', fontSize: 14, fontWeight: 800 }} />
          }
        </Box>}
        <Link href='/' className={scss.sidebarNarrow__logoWrapper}>
          <img className={scss.sidebarNarrow__logoIcon} alt="" src="/logo_small.svg" />
        </Link>

        <Box className={scss.sidebarNarrow__menu}>
          <NavLink
            className={scss.sidebarNarrow__menu_menuItem}
            to="/overview"
            end
            style={activeLinkStyle}
            data-animate-on-scroll
          >
            <SpaceDashboardIcon />
          </NavLink>
          <NavLink
            className={scss.sidebarNarrow__menu_menuItem}
            to="/feed/general"
            end
            style={activeFeedLinkStyle}
            data-animate-on-scroll
          >
            <FeedRoundedIcon />
          </NavLink>
          <NavLink
            className={scss.sidebarNarrow__menu_menuItem}
            to="/leads"
            style={activeLinkStyle}
            data-animate-on-scroll
          >
            <PeopleRoundedIcon />
          </NavLink >
          {userInformation.organizationId !== 'none' && (
            <NavLink
              className={scss.sidebarNarrow__menu_menuItem}
              to="/team-management"
              style={activeLinkStyle}
              data-animate-on-scroll
            >
              <BadgeIcon />
            </NavLink>
          )}
          <NavLink
            className={scss.sidebarNarrow__menu_menuItem}
            to="/settings/prompts/default"
            style={activeSettingLinkStyle}
            data-animate-on-scroll
          >
            <SettingsIcon />
          </NavLink>
        </Box>
      </Box>
    );
  }

  return (
    <Box className={scss.sidebar}>
      {isToggleSideBarVisible && <Box className={scss.buttonWide} onClick={toogleSideBar}>
        {
          isSideBarWide
            ? <ArrowBackIosNewIcon sx={{ color: '#191F2E', fontSize: 14, fontWeight: 800 }} />
            : <ArrowForwardIosIcon sx={{ color: '#191F2E', fontSize: 14, fontWeight: 800 }} />
        }
      </Box>}
      <Link href='/' className={scss.sidebar__logoWrapper}>
        <img className={scss.sidebar__logoIcon} alt="" src="/logo.svg" />
      </Link>
      <Box className={scss.sidebar__profile}>
        <Stack direction="row">
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={
              <Avatar
                alt="L"
                variant="rounded"
                sx={{ width: '18px', height: '18px' }}
              >
                <LinkedInIcon sx={{
                  width: '25px',
                  height: '25px',
                  fill: '#007EBB',
                  backgroundColor:
                    'white',
                }} />
              </Avatar>
            }
          >
            <Avatar
              alt={userInformation.firstName}
              src={userInformation.profileImg}
              sx={{ width: '56px', height: '56px' }}
            />
          </Badge>
        </Stack>
        <Box className={scss.sidebar__profile_firstLastName}>
          {userInformation.firstName} {userInformation.lastName}
        </Box>
        <Box className={scss.sidebar__profile_organizationName}>
          {userInformation.organizationName === 'none' ? '' : userInformation.organizationName}
        </Box>
        <Box className={scss.sidebar__profile_organizationRole}>
          {userInformation.organizationRole === 'none' ? '' : userInformation.organizationRole}
        </Box>
      </Box>

      <Box className={scss.sidebar__menu}>
        <NavLink
          className={scss.sidebar__menu_menuItem}
          to="/overview"
          end
          style={activeLinkStyle}
          data-animate-on-scroll
        >
          <SpaceDashboardIcon />
          <Box className={scss.sidebar__menu_text}>Overview</Box>
        </NavLink>
        <NavLink
          className={scss.sidebar__menu_menuItem}
          to="/feed/general"
          end
          style={activeFeedLinkStyle}
          data-animate-on-scroll
        >
          <FeedRoundedIcon />
          <Box className={scss.sidebar__menu_text}>Feed</Box>
        </NavLink>
        <NavLink
          className={scss.sidebar__menu_menuItem}
          to="/leads"
          style={activeLinkStyle}
          data-animate-on-scroll
        >
          <PeopleRoundedIcon />
          <Box className={scss.sidebar__menu_text}>Leads</Box>
        </NavLink >
        {userInformation.organizationId !== 'none' && (
          <NavLink
            className={scss.sidebar__menu_menuItem}
            to="/team-management"
            style={activeLinkStyle}
            data-animate-on-scroll
          >
            <BadgeIcon />
            <Box className={scss.sidebar__menu_text}>Team Management</Box>
          </NavLink>
        )}
        <NavLink
          className={scss.sidebarNarrow__menu_menuItem}
          to="/settings/prompts/default"
          style={activeSettingLinkStyle}
          data-animate-on-scroll
        >
          <SettingsIcon />
          <Box className={scss.sidebar__menu_text}>Settings</Box>
        </NavLink>
      </Box>
      {
        (checkingCookiesProcess || isDataLoading)
          ? (
            <Box sx={{ height: '4px', mt: 1, color: '#6d30b7' }}>
              <LinearProgress sx={{ width: '100%' }} color='inherit' />
            </Box>
          )
          : <Box sx={{ height: '4px', mt: 1 }}></Box>
      }

      {
        isButtonUpgradePlanVisible && (
          <Button className={scss.sidebar__upgradePlanButton} onClick={toogleUpgradePlanPopUp}>
            🚀 Upgrade Plan
          </Button>
        )
      }

      {
        (!checkOfValidCookie) ? (
          <Box
            className={scss.sidebar__cookieBlock}
            sx={{ backgroundColor: checkOfValidCookie ? '#6F7D9B' : '#807B6A' }}
          >
            <WarningRoundedIcon />
            <Box className={scss.sidebar__cookieBlock_message}>
              LinkedIn cookies have expired.
            </Box>
            <Box className={scss.sidebar__cookieBlock_info}>
              To see the last updates please log in to
              <a
                className={scss.sidebar__cookieBlock_link}
                rel='noreferrer'
                target='_blank'
                href='https://www.linkedin.com'>
                LinkedIn
              </a>.
            </Box>
          </Box>
        )
          : <Box sx={{ height: '119px', mt: '12px' }} />
      }



      {/* <Box className={scss.sidebar__statisticBlockWrapper}>
        {!isDataLoading && (<>
          <Box className={scss.sidebar__statisticBlockWrapper_replyInfoWrapper}>
            <Box className={scss.sidebar__statisticBlockWrapper_statisticNumber}>
              <Box className={scss.sidebar__statisticBlockWrapper_numberText}>
                {replies}
              </Box>
              <Box className={scss.sidebar__statisticBlockWrapper_iconAndTextWrapper} >
                <ReplyOutlinedIcon
                  className={scss.feedBlack24dpIcon}
                />
                <Box>
                  Replies
                  <Typography sx={{ fontFamily: 'Inter', fontSize: 9 }}>*per month</Typography>
                </Box>
              </Box>
            </Box>

            <Divider orientation="vertical" flexItem color='#6F7D9B' sx={{ width: '1px' }} />

            <Box className={scss.sidebar__statisticBlockWrapper_statisticNumber}>
              <Box className={scss.sidebar__statisticBlockWrapper_numberText_pending}>
                {pendingReplies}
              </Box>
              <Box className={scss.sidebar__statisticBlockWrapper_iconAndTextWrapper} >
                Pending replies
              </Box>
            </Box>
          </Box>

          <Box className={scss.sidebar__frameParent}>
            <Box className={scss.sidebar__frameParent_numberText}>
              {countOfRepliedFeed}
            </Box>
            <Box className={scss.sidebar__frameParent_visibilityParent}>
              <img src='./chat.svg' alt='' />
              <Box>My comments</Box>
            </Box>
          </Box>

          <Box className={scss.sidebar__frameParent}>
            <Box className={scss.sidebar__frameParent_numberText}>
              {profileViews}
            </Box>
            <Box className={scss.sidebar__frameParent_visibilityParent}>
              <VisibilityRoundedIcon />
              <Box>Profile views</Box>
            </Box>
          </Box>
          <Box className={scss.sidebar__frameParent}>
            <Box className={scss.sidebar__frameParent_numberText}>
              <Box sx={{ display: 'flex', gap: 1 }}>
                {totalCountOfLeads} /
                <Box sx={{ color: '#c7d1e5' }}>
                  {leadsLimit}
                </Box>
              </Box>
            </Box>
            <Box className={scss.sidebar__frameParent_visibilityParent}>
              <img src={'./leads_activity.svg'} alt='' />
              <Box>Leads Count</Box>
            </Box>
          </Box>
        </>
        )}
      </Box> */}
    </Box >
  );
};
