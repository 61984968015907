import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { AccessAppStatuses } from '../../../types/PPG/AccessAppStatuses.enum';

interface AsyncThunkConfig {
  state: RootState;
}

export function createAsyncThunkCheckSubscription<Returned, ThunkArg = void>(
  type: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payloadCreator: (arg: ThunkArg, thunkAPI: { getState: () => RootState; rejectWithValue: (value: string) => any }) => Promise<Returned>
) {
  
  return createAsyncThunk<Returned, ThunkArg, AsyncThunkConfig>(
    type,
    async (arg: ThunkArg, thunkAPI) => {
      const state = thunkAPI.getState();
      const { accessAppStatuses } = state.accountDetailsReducer;

      if(accessAppStatuses === AccessAppStatuses.PAYMENT_REQUIRED) {
        return thunkAPI.rejectWithValue(AccessAppStatuses.API_BLOCKED)
      }

      return await payloadCreator(arg, thunkAPI);
    },
  );
}



