import { getRequest, postRequest } from "../../../general/https";
import { AddUserLikeBotPayload, AddUserLikeBotResponse, CheckFeedDailyPayload, CheckFeedDailyResponse, CheckFeedInitialPayload, CheckFeedInitialResponse, CheckValidCookieForBotResponse, CheckValidCookiesForBotPayload, DeleteUserFromBotsPayload, DeleteUserFromBotsResponse, GetBotAndCheckValidCookiesResponse, GetBotStatisticResponse, GetNeedAdminCheckSnlinksResponse, GetUsersResponse, LoadLeadsPayload, LoadLeadsResponse, StopWorkPayload, StopWorkResponse } from "./types";
import { checkErrorStatus } from "../../../general/checkErrorStatus";
import { createAsyncThunkCheckSubscription } from "../../middlewares/thunkCondition";

export const getBots = createAsyncThunkCheckSubscription<GetBotAndCheckValidCookiesResponse>(
  'botManagementSlice/getBots',
  async (_, {rejectWithValue}) => {
    try {
      const response = await getRequest(`/parsing/get-all-bots-and-check-cookies`, false)
      checkErrorStatus(response)
      return response.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const checkValidCookiesForBot = createAsyncThunkCheckSubscription<CheckValidCookieForBotResponse, CheckValidCookiesForBotPayload>(
  'botManagementSlice/checkValidCookiesForBot',
  async (payload, {rejectWithValue}) => {
    try {
      const response = await getRequest(`/parsing/check-valid-cookie-for-bot/${payload.userUuid}`, false)
      checkErrorStatus(response)
      console.log(response)
      return response.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
  
)

export const checkFeedDaily = createAsyncThunkCheckSubscription<CheckFeedDailyResponse, CheckFeedDailyPayload>(
  'botManagementSlice/checkFeedDaily',
  async (payload, {rejectWithValue}) => {
    try {
      const response = await postRequest(`/parsing/check-feed-daily-manual`, payload, false)
      checkErrorStatus(response)
      return response.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }  
)

export const checkFeedInitial = createAsyncThunkCheckSubscription<CheckFeedInitialResponse,CheckFeedInitialPayload>(
  'botManagementSlice/checkFeedInitial',
  async (payload, {rejectWithValue}) => {
    try {
      const response = await postRequest(`/parsing/check-feed-initial-sn-link`, payload, false)
      checkErrorStatus(response)
      return response.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }  
)

export const getUsers = createAsyncThunkCheckSubscription<GetUsersResponse>(
  'botManagementSlice/getUsers',
  async (_, {rejectWithValue}) => {
    try {
      const response = await getRequest(`/users-information/get-all`, false)
      checkErrorStatus(response)
      return response.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }  
)

export const addUserLikeBot = createAsyncThunkCheckSubscription<AddUserLikeBotResponse,AddUserLikeBotPayload>(
  'botManagementSlice/addUserLikeBot',
  async (payload, {rejectWithValue}) => {
    const body = {userUuid: payload.user.uidd}
    try {
      const response = await postRequest(`/parsing/add-user-like-bot`, body, false)
      checkErrorStatus(response)
      return response.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }  
)

export const deleteUserFromBots = createAsyncThunkCheckSubscription<DeleteUserFromBotsResponse, DeleteUserFromBotsPayload>(
  'botManagementSlice/deleteUserFromBots',
  async (payload, {rejectWithValue}) => {
    try {
      const response = await postRequest(`/parsing/delete-user-from-bots`, payload, false)
      checkErrorStatus(response)
      return response.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }  
)

export const stopWork = createAsyncThunkCheckSubscription<StopWorkResponse, StopWorkPayload>(
  'botManagementSlice/stopWork',
  async (payload, {rejectWithValue}) => {
    try {
      const response = await postRequest(`/parsing/stop-work`, payload, false)
      checkErrorStatus(response)
      if(!response.data.cronJobDeleted) {
        throw new Error('Cannot stop work.')
      }
      return response.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }  
)

export const loadLeadsSnGet = createAsyncThunkCheckSubscription<LoadLeadsResponse, LoadLeadsPayload>(
  'botManagementSlice/loadLeadsSnGet',
  async (payload, {rejectWithValue}) => {
    try {
      const response = await postRequest(`/parsing/load-db-of-leads-using-sn-get-request`, payload, false)
      checkErrorStatus(response)
      return response.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }  
)

export const getBotStatistic = createAsyncThunkCheckSubscription<GetBotStatisticResponse>(
  'botManagementSlice/getBotStatistic',
  async (_, {rejectWithValue}) => {
    try {
      const response = await getRequest(`/parsing/get-all-bots-statistic`, false)
      checkErrorStatus(response)
      return response.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
  

export const getNeedAdminCheckSnlinks = createAsyncThunkCheckSubscription<GetNeedAdminCheckSnlinksResponse>(
  'botManagementSlice/getNeedAdminCheckSnlinks',
   async (_, {rejectWithValue}) => {
    try {
      const response = await getRequest(`/parsing/get-needAdminCheck-sn-link`, false)
      checkErrorStatus(response)
      return response.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }  
)
