import { useAppSelector } from '../typedHooks';

export const useUsersStatisticData = () => {
  const {
    isGeneralStatisticRequest,
    isCampaignStatisticRequest,
    isExportRequest,
    //general statistic data
    profileViews,
    leadsCount,
    leadsFirstConnectionCount,
    leadsSecondConnectionCount,
    commentsCount,
    repliesCount,
    likesCount,
    leadsReactionsCount,
    conversionRate,
    pendingReactions,
    leadsLimit,
    //campaign statistic data
    commentsResponseArr,
    leadsLikeResponseArr,
    leadsRepliesResponseArr,
    //custom users fields
    dateRange,
  } = useAppSelector((state) => state.usersStatisticSliceReducer);

  return {
    isGeneralStatisticRequest,
    isCampaignStatisticRequest,
    isExportRequest,
    //general statistic data
    profileViews,
    leadsCount,
    leadsFirstConnectionCount,
    leadsSecondConnectionCount,
    commentsCount,
    repliesCount,
    likesCount,
    leadsReactionsCount,
    conversionRate,
    pendingReactions,
    leadsLimit,
    //campaign statistic data
    commentsResponseArr,
    leadsLikeResponseArr,
    leadsRepliesResponseArr,
    //custom users fields
    dateRange,
  };
};