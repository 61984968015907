import { Box, Typography } from "@mui/material";
import React from "react";
import styles from "../UsersStatistic.module.scss";
import { useUsersStatisticData } from "../../../utils/redux/storeHooks/dataHooks/useUsersStatisticData";
import GroupIcon from '@mui/icons-material/Group';
import ForumIcon from '@mui/icons-material/Forum';
import ReplyIcon from '@mui/icons-material/Reply';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

export const GeneralListStatistic = () => {
  const {
    leadsCount,
    commentsCount,
    profileViews,
    conversionRate,
    leadsReactionsCount,
    leadsLimit
  } = useUsersStatisticData();

  const iconGeneralStatisticStyle = {
    color: '#fff',
    fontSize: 28
  }

  const generalStatisticList = [
    {
      title: 'Total number of leads',
      value: leadsCount,
      limitValue: leadsLimit,
      desciption: 'Leads tracked in heet',
      icon: <GroupIcon sx={iconGeneralStatisticStyle} />
    },
    {
      title: 'Total comments sent',
      value: commentsCount,
      limitValue: null,
      desciption: 'Comments sent from the app',
      icon: <ForumIcon sx={iconGeneralStatisticStyle} />
    },
    {
      title: 'Reactions',
      value: leadsReactionsCount,
      limitValue: null,
      desciption: 'Likes and replies to your comments',
      icon: <ReplyIcon sx={iconGeneralStatisticStyle} />
    },
    {
      title: 'Profile views',
      value: profileViews,
      limitValue: null,
      desciption: 'LinkedIn profile views',
      icon: <VisibilityIcon sx={iconGeneralStatisticStyle} />
    },
    // {
    //   title: 'Conversion rate',
    //   value: conversionRate,
    //   limitValue: null,
    //   desciption: 'Response rate for comments sent',
    //   icon: <TrendingUpIcon sx={iconGeneralStatisticStyle} />
    // },
  ]

  return (
    <Box className={styles.usersStatistic__generalStatisticList}>
      {generalStatisticList.map(card => (
        <Box key={card.title} className={styles.usersStatistic__generalStatisticList__card}>
          <Box className={styles.usersStatistic__generalStatisticList__imgContainer}>
            {card.icon}
          </Box>
          <Typography className={styles.usersStatistic__generalStatisticList__cardTitle}>
            {card.title}
          </Typography>
          <Box className={styles.usersStatistic__generalStatisticList__cardValue}>
            {card.limitValue ? (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
                {card.value}
                <Box sx={{ opacity: 0.5 }}>
                  /{card.limitValue}
                </Box>
              </Box>
            ) : (
              <Box className={styles.usersStatistic__generalStatisticList__cardValue}>
                {card.value}
              </Box>
            )}
          </Box>
          <Box className={styles.usersStatistic__generalStatisticList__cardDescriptiobn}>
            {card.desciption}
          </Box>
        </Box>
      ))}
    </Box >
  )
}
