import { Box, ListItemIcon, ListItemText, Menu, MenuItem, Typography, Zoom } from "@mui/material";
import React, { useState } from "react";
import { MainActionButton } from "../../../components/actionButton/MainActionButton";
import { usePopupActions } from "../../../utils/redux/storeHooks/actionsHooks/usePopupActions";
import { useSidebarData } from "../../../utils/redux/storeHooks/dataHooks/useSidebarData";
import { TooltipApp } from "../../../components/tooltipApp/TooltipApp";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useAccountDetailsData } from "../../../utils/redux/storeHooks/dataHooks/useAccountDetailsData";
import { Payment_status } from "../../../types/PPG/Payment_status";

export const ImportDropDownMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { setPopUpAddLeadsUseSnOpen, setPopupImportCSVOpen } = usePopupActions();
  const { totalCountOfLeads, leadsLimit } = useSidebarData();
  const { activeSubscription } = useAccountDetailsData();

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPopLoadSnLink = () => {
    setPopUpAddLeadsUseSnOpen(true)
    setAnchorEl(null);
  }

  const openPopUpImportCsv = () => {
    setPopupImportCSVOpen(true)
    setAnchorEl(null);
  }

  if (!activeSubscription
    || activeSubscription.paymentStatus !== Payment_status.CONFIRMED
  ) {
    return (
      <TooltipApp TransitionComponent={Zoom} placement="bottom-start" title={
        <>
          <Typography sx={{ fontSize: 14, textAlign: 'center', padding: '4px 4px' }}>
            {"This feature available, after confirm payment."}
          </Typography>
        </>
      }>
        <Box>
          <MainActionButton
            title='Add leads'
            icon={<PersonAddIcon />}
            isDisabled

          />
        </Box>
      </TooltipApp>
    )
  }

  if (totalCountOfLeads >= leadsLimit) {
    return (
      <TooltipApp TransitionComponent={Zoom} placement="bottom-start" title={
        <>
          <Typography sx={{ fontSize: 14 }}>
            {"Sorry, you've reached the limit for tracking leads. Please contact the admin."}
          </Typography>
        </>
      }>
        <Box>
          <MainActionButton
            title='Add leads'
            icon={<PersonAddIcon />}
            actionOnClick={handleOpenMenu}
            isDisabled={totalCountOfLeads >= leadsLimit}
          />
        </Box>
      </TooltipApp>
    )
  }

  return (
    <>
      <MainActionButton
        title='Add leads'
        icon={<PersonAddIcon />}
        actionOnClick={handleOpenMenu}
        isDisabled={totalCountOfLeads >= leadsLimit}
      />
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: {
            boxShadow: '4px 4px 8px 0px rgba(0, 0, 0, 0.15)',
            width: '220px',
            height: '90px',
            overflowX: 'unset',
            overflowY: 'unset',
          },
        }}
      >
        <MenuItem
          sx={{
            height: '40px',
          }}
          onClick={openPopUpImportCsv}

        >
          <ListItemIcon>
            <img alt="" src="/importCsv.svg" />
          </ListItemIcon>
          <ListItemText>
            <Typography sx={{ fontFamily: 'Kanit', fontWeight: 300, fontSize: 16 }}>
              Import CSV
            </Typography>
          </ListItemText>
        </MenuItem>

        <MenuItem
          sx={{
            height: '40px',
          }}
          onClick={openPopLoadSnLink}
        >
          <ListItemIcon>
            <img alt="" src="/snIcon.svg" />
          </ListItemIcon>
          <ListItemText>
            <Typography sx={{ fontFamily: 'Kanit', fontWeight: 300, fontSize: 16 }}>
              Use Sales Navigator
            </Typography>
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  )
}

