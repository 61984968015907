export type Invoice = {
  id: number,
  placedAtUtc: Date,
  invoiceUrl: string,
  customerBillingEmail: string,
  customerBillingFirstName: string,
  customerBillingLastName: string,
  orderStatusId: number,
  paymentMethodId: number,
  orderStatusName: string,
  paymentMethodName: string,
}

export enum InvoiceStatus {
  Waiting = 1,
  Canceled = 2,
  Refunded = 3,
  Chargeback = 4,
  Processed = 5
}
