import React, { useState } from 'react';
import styles from "./CancelSubscription.module.scss";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Button } from '@material-ui/core';
import IconButton from '@mui/material/IconButton';
import { usePopupActions } from '../../../utils/redux/storeHooks/actionsHooks/usePopupActions';
import { Box, Typography } from '@mui/material';
import { MainActionButton } from '../../actionButton/MainActionButton';
import { PaymentFlow } from '../../../types/PPG/paymentFlow.enum';
import { useAccountDetailsData } from '../../../utils/redux/storeHooks/dataHooks/useAccountDetailsData';
import { useAccountDetailsActions } from '../../../utils/redux/storeHooks/actionsHooks/useAccountDetailsActions';
import { getUserInformationFromlocalStorage } from '../../../utils/general/get_set_user_information';
import { StyledTextFieldWithError } from '../../styledTextField/StyledTextField';

export const PopUpCancelSubscription = () => {
  const { isCancelPlanRequest, activeSubscription } = useAccountDetailsData();
  const { cancelPlan } = useAccountDetailsActions();
  const { setPopUpCancelSubscriptionOpen } = usePopupActions();
  const { paymentFlow } = getUserInformationFromlocalStorage();
  const [reasonText, setReasonText] = useState('')
  const [error, setError] = useState(false);

  const handleBlur = () => {
    if (!reasonText.trim()) setError(true);
    else setError(false);
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value
    setReasonText(value)
    if (value) {
      setError(false);
    } else {
      setError(true);
    }
  }

  const closePopUp = () => {
    setPopUpCancelSubscriptionOpen(false)
  }
  const onCancelPlan = async () => {
    switch (paymentFlow) {
      case PaymentFlow.PPG:
        await cancelPlan({
          subscriptionId: activeSubscription.ppgId,
          reasonText: reasonText.trim() || 'Manual terminate subscription'
        })
        setPopUpCancelSubscriptionOpen(false)
        return;
      case PaymentFlow.INVOICE:
        window.open(`https://meetings-eu1.hubspot.com/michael-pihosh/heet`, '_blank');
        setPopUpCancelSubscriptionOpen(false)
        return;
    }
  }

  return (
    <Box className={styles.popup}>
      <Box className={styles.header}>
        <Box className={styles.header__title}>
          Cancel Subscription
        </Box>
        <IconButton
          color="default"
          onClick={closePopUp}
          className={styles.header__closeButton}
        >
          <CloseRoundedIcon sx={{ fill: '#191F2E' }} />
        </IconButton>
      </Box>
      <Box className={styles.content}>
        <Typography className={styles.content__text}>
          Do you really want to cancel subscription?
        </Typography>
        <Typography className={styles.content__text__sub}>
          You have access to the app until the end of the paid period.
        </Typography>
        <StyledTextFieldWithError
          required
          fullWidth
          placeholder='Reason for cancellation'
          variant="outlined"
          value={reasonText}
          onChange={(e) => onInputChange(e)}
          className={styles.reasonTextField}
          onBlur={handleBlur}
          error={error}
          helperText={error ? 'This field is required' : ''}
          margin="normal"
        />
      </Box>

      <Box className={styles.footer}>
        <Button
          variant='text'
          onClick={closePopUp}
          className={styles.footer__cancel}
        >
          Cancel
        </Button>
        <MainActionButton
          title='Confirm'
          height={40}
          width={80}
          fontWeight={300}
          actionOnClick={onCancelPlan}
          isLoading={isCancelPlanRequest}
          isDisabled={isCancelPlanRequest || !reasonText}
        />
      </Box>
    </Box>
  );
};
