import React, { useState } from 'react';
import styles from "./ManageUser.module.scss";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Button } from '@material-ui/core';
import IconButton from '@mui/material/IconButton';
import { usePopupActions } from '../../../utils/redux/storeHooks/actionsHooks/usePopupActions';
import { Avatar, Box, CircularProgress, Divider, Stack, Typography } from '@mui/material';
import { MainActionButton } from '../../actionButton/MainActionButton';
import { useAdminPageData } from '../../../utils/redux/storeHooks/dataHooks/useAdminPageData';
import { useAdminPageActions } from '../../../utils/redux/storeHooks/actionsHooks/useAdminPageActions';
import { StyledTextField } from '../../styledTextField/StyledTextField';
import Teams from '../../../types/entities/teams';
import { Roles } from '../../../utils/general/roles';
import { FieldType } from '../../../types/pages/adminPage/filedType';
import { StyledAutocomplete } from '../../styledAutocomplete/StyledAutocomplete';
import { StyledSwitcher } from '../../styledComponents/StyledSwitcher';
import { PaymentFlow } from '../../../types/PPG/paymentFlow.enum';
import { SubscriptionPlan } from '../../../types/PPG/SubscriptionPlan';
import { UserPermissionList } from '../../../types/PPG/UserPermissionList';
import DatePicker from "react-datepicker";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { getActiveSubscriptionWithPaymentFlow } from '../../../utils/pages/adminPage/getActiveSubscription';

export const PopUpManageUser = () => {
  const { setManageCurrentUser, updateUser, createManualSubscription, deleteManualSubscription } = useAdminPageActions();
  const { setPopupManageUserOpen } = usePopupActions();
  const { manageCurrentUser, teams, isUserUpdating, isCreateManualSubscription, isDeleteManualSubscription } = useAdminPageData();

  const activeSubscription = getActiveSubscriptionWithPaymentFlow(manageCurrentUser)

  const [inputErrors, setInputError] = useState([]);
  const [limit, setLimit] = useState<number | string>(manageCurrentUser.slots)
  const [proxy, setProxy] = useState<string>(manageCurrentUser.proxy === 'none' ? '' : manageCurrentUser.proxy)
  const [team, setTeam] = useState<Teams>(teams.find(team => team.teamsUuid === manageCurrentUser.organizationId) || null)
  const [roles, setRoles] = useState<string[] | string>(manageCurrentUser.roles)
  const [email, setEmail] = useState<string>(manageCurrentUser.email)

  const [isTogglerSelect, setIsTogglerSelect] = useState(activeSubscription?.paymentFlow === PaymentFlow.INVOICE);
  const [plan, setPlan] = useState<SubscriptionPlan>(activeSubscription?.plan || SubscriptionPlan.STARTER)
  const [permission, setPermission] = useState<UserPermissionList>(activeSubscription?.permission || UserPermissionList.ALLOW_APP_ACCESS)
  const [trialDate, setTrialDate] = React.useState<Date | null>(null);

  const today = new Date();
  const oneMonthAhead = new Date();
  oneMonthAhead.setMonth(today.getMonth() + 1);
  const isTrial = Boolean(activeSubscription?.isTrial)

  const closePopUp = () => {
    setPopupManageUserOpen(false)
    setManageCurrentUser(null)
  }

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const detectError = (inputType: FieldType, value: any) => {
    switch (inputType) {
      case FieldType.limit:
        isNaN(Number(value)) || !value
          ? setInputError([...inputErrors, inputType])
          : setInputError(inputErrors.filter(error => error !== inputType))
        return
      case FieldType.roles:
        !value.length
          ? setInputError([...inputErrors, inputType])
          : setInputError(inputErrors.filter(error => error !== inputType))
        return
      case FieldType.email:
        !isValidEmail(value) && value.length
          ? setInputError([...inputErrors, inputType])
          : setInputError(inputErrors.filter(error => error !== inputType))
        return
      case FieldType.permission:
        !value.length
          ? setInputError([...inputErrors, inputType])
          : setInputError(inputErrors.filter(error => error !== inputType))
        return
      case FieldType.plan:
        !value.length
          ? setInputError([...inputErrors, inputType])
          : setInputError(inputErrors.filter(error => error !== inputType))
        return
    }
    return
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onInputChange = (inputType: FieldType, value: any) => {
    detectError(inputType, value)
    switch (inputType) {
      case FieldType.limit:
        setLimit(value.trim())
        break;
      case FieldType.proxy:
        setProxy(value.trim())
        break;
      case FieldType.team:
        setTeam(value)
        break;
      case FieldType.roles:
        setRoles(value)
        break;
      case FieldType.email:
        setEmail(value.trim())
        break;
      case FieldType.plan:
        setPlan(value.trim())
        break;
      case FieldType.permission:
        setPermission(value.trim())
        break;
    }
  }

  const handleTrialDateChange = (date: Date) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate());
    newDate.setHours(0, 0, 0, 0);
    setTrialDate(newDate);
  };

  const sendUpdateData = () => {
    updateUser({
      userUuid: manageCurrentUser.uidd,
      limit: Number(limit),
      proxy,
      team,
      roles: [...roles],
      email,
      subscriptionData: activeSubscription ? {
        subscriptionId: activeSubscription.ppgId,
        plan,
        permission,
        trialEndDate: trialDate ? new Date(new Date(trialDate).setDate(trialDate.getDate() + 1)) : null,
      } : null,
    })
  }
  const tooglePaymentFlow = () => {
    if (manageCurrentUser.paymentFlow === PaymentFlow.PPG) {
      setIsTogglerSelect(true)
      createManualSubscription({
        userId: manageCurrentUser.id
      })
    } else {
      setIsTogglerSelect(false)
      deleteManualSubscription({
        userId: manageCurrentUser.id,
        subscriptionId: activeSubscription?.ppgId
      })
    }
  }

  return (
    <Box className={styles.popup}>
      <Box className={styles.header}>
        <Box className={styles.header__title}>
          Manage user
        </Box>
        <IconButton
          color="default"
          onClick={closePopUp}
          className={styles.header__closeButton}
        >
          <CloseRoundedIcon sx={{ fill: '#191F2E' }} />
        </IconButton>
      </Box>
      <Box className={styles.content}>
        <Box className={styles.profileWrapper}>
          <a
            className={styles.firstLastNameText}
            href={`https://${styles.dashEntityUrn}`}
            target='_blank'
            rel="noreferrer"
          >
            <Stack direction="row">
              <Avatar
                alt=""
                src={manageCurrentUser.profileImg}
                sx={{ width: '40px', height: '40px' }}
              />
            </Stack>
          </a>
          <Box className={styles.userInfo} sx={{ marginLeft: '12px' }}>
            <Box className={styles.firstLastNameText}>

              {manageCurrentUser.firstName} {manageCurrentUser.lastName}
            </Box>
            <Box className={styles.titleText}>
              {manageCurrentUser.title || ''}
            </Box>
          </Box>
        </Box>
        <Divider sx={{ width: '100%' }} />
        <Box className={styles.content__settings}>
          <Box className={styles.content__setting}>
            <Typography className={styles.content__settingTitle}>
              Leads limit:
            </Typography>
            <StyledTextField
              value={limit}
              sx={{ width: '400px' }}
              onChange={(e) => onInputChange(FieldType.limit, e.target.value)}
              error={inputErrors.some(error => error === 'limit')}
              helperText={inputErrors.some(error => error === 'limit') && 'This field need value of only numbers'}
              required
            />
          </Box>

          <Box className={styles.content__setting}>
            <Typography className={styles.content__settingTitle}>
              Proxy:
            </Typography>
            <StyledTextField
              onChange={(e) => onInputChange(FieldType.proxy, e.target.value)}
              value={proxy}
              sx={{ width: '400px' }}
            />
          </Box>

          <Box className={styles.content__setting}>
            <Typography className={styles.content__settingTitle}>
              Team:
            </Typography>
            <StyledAutocomplete
              clearIcon={null}
              disablePortal
              options={teams}
              getOptionLabel={(option: Teams) => `${option.teamName}`}
              renderOption={(props, option: Teams) => (
                <li {...props}>
                  {option.teamName}
                </li>
              )} sx={{ width: '400px' }}
              value={team}
              onChange={(event, newValue: Teams | null) => onInputChange(FieldType.team, newValue)}
              ListboxProps={{ sx: { "& li": { fontFamily: "Kanit", fontWeight: 300 } } }}
              renderInput={(params) => <StyledTextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                }}
              />}
            />
          </Box>

          <Box className={styles.content__setting}>
            <Typography className={styles.content__settingTitle}>
              Roles:
            </Typography>
            <StyledAutocomplete
              clearIcon={null}
              multiple
              disablePortal
              options={Roles}
              sx={{ width: '400px' }}
              value={roles}
              onChange={(event, newValue: string | null) => onInputChange(FieldType.roles, newValue)}
              ListboxProps={{ sx: { "& li": { fontFamily: "Kanit", fontWeight: 300 } } }}
              renderInput={(params) => <StyledTextField
                error={inputErrors.some(error => error === 'roles')}
                helperText={inputErrors.some(error => error === 'roles') && 'select at least one role'}
                {...params}
                InputProps={{
                  ...params.InputProps,
                }}
              />}
            />
          </Box>

          <Box className={styles.content__setting}>
            <Typography className={styles.content__settingTitle}>
              Email:
            </Typography>
            <StyledTextField
              error={inputErrors.some(error => error === 'email')}
              helperText={inputErrors.some(error => error === 'email') && 'Inccorect email'}
              onChange={(e) => onInputChange(FieldType.email, e.target.value)}
              value={email}
              sx={{ width: '400px' }}
            />
          </Box>
          {isTrial && <Box className={styles.trialDateBox}>
            <Typography className={styles.content__settingTitle}>
              Trial End Date:
            </Typography>
            <div className={styles.datePickerWrapper}>
              <DatePicker
                selected={trialDate}
                onChange={handleTrialDateChange}
                placeholderText="mm/dd/yyyy"
                icon={<CalendarMonthIcon sx={{ color: '#6F7D9B' }} />}
                minDate={new Date()}
                maxDate={oneMonthAhead}
                showIcon={!trialDate}
                isClearable
                clearButtonClassName="clearButton"
              />
            </div>
          </Box>
          }
          <Box className={`${styles.content__setting} ${styles.content__setting__toggler}`}>
            <Typography className={styles.content__settingTitle}>
              Set manual subscription:
            </Typography>
            <StyledSwitcher
              checked={isTogglerSelect}
              onChange={() => tooglePaymentFlow()}
              disabled={
                isCreateManualSubscription
                || isDeleteManualSubscription
                || activeSubscription?.paymentFlow === PaymentFlow.PPG
              }
            />
            {(isCreateManualSubscription || isDeleteManualSubscription) && <CircularProgress sx={{}} color='inherit' size={24} />}
          </Box>

          <Box className={`${styles.content__subscription} ${activeSubscription?.paymentFlow === PaymentFlow.INVOICE
            && styles.content__subscription__open}`}>
            <Box className={styles.content__setting}>
              <Typography className={styles.content__settingTitle}>
                Plan:
              </Typography>
              <StyledAutocomplete
                clearIcon={null}
                disablePortal
                options={Object.values(SubscriptionPlan)}
                getOptionLabel={(option: SubscriptionPlan) => `${option}`}
                renderOption={(props, option: SubscriptionPlan) => (
                  <li {...props}>
                    {option}
                  </li>
                )} sx={{ width: '400px' }}
                value={plan}
                onChange={(event, newValue: SubscriptionPlan | null) => onInputChange(FieldType.plan, newValue)}
                ListboxProps={{ sx: { "& li": { fontFamily: "Kanit", fontWeight: 300 } } }}
                renderInput={(params) => <StyledTextField
                  error={inputErrors.some(error => error === FieldType.plan)}
                  helperText={inputErrors.some(error => error === FieldType.plan) && 'select at least one plan'}
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                  }}
                />}
              />
            </Box>

            <Box className={styles.content__setting}>
              <Typography className={styles.content__settingTitle}>
                Permission:
              </Typography>
              <StyledAutocomplete
                clearIcon={null}
                disablePortal
                options={Object.values(UserPermissionList)}
                getOptionLabel={(option: UserPermissionList) => `${option}`}
                renderOption={(props, option: UserPermissionList) => (
                  <li {...props}>
                    {option}
                  </li>
                )} sx={{ width: '400px' }}
                value={permission}
                onChange={(event, newValue: UserPermissionList | null) => onInputChange(FieldType.permission, newValue)}
                ListboxProps={{ sx: { "& li": { fontFamily: "Kanit", fontWeight: 300 } } }}
                renderInput={(params) => <StyledTextField
                  error={inputErrors.some(error => error === FieldType.permission)}
                  helperText={inputErrors.some(error => error === FieldType.permission) && 'select at least one permission'}
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                  }}
                />}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className={styles.footer}>
        <Button
          variant='text'
          onClick={closePopUp}
          className={styles.footer__cancel}
        >
          Cancel
        </Button>
        <MainActionButton
          title='Update'
          height={40}
          width={66}
          fontWeight={300}
          isDisabled={!!inputErrors.length || isUserUpdating}
          actionOnClick={sendUpdateData}
          isLoading={isUserUpdating}
        />
      </Box>
    </Box>
  );
};
