import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { tableLeadsReducer } from './slices/tableLeadsSlice/tableLeadsSlice'
import { notificationReducer } from './slices/notificationSlice/notificationSlice'
import { popupReducer } from './slices/popupSlice/popupSlice'
import { sidebarReducer } from './slices/sidebarSlice/sidebarSlice'
import { tableTeamManagementReducer } from './slices/tableTeamManagmentSlice/teamManagementSlice'
import { newFeedReducer } from './slices/newFeedSlice/newFeedSlice'
import { automationReducer } from './slices/automationPageSlice/automationSlice'
import { botManagementReducer } from './slices/botManagementSlice/botManagementSlice'
import { leadsNotificationReducer } from './slices/leadsNotificationSlice/leadsNotificationSlice'
import { adminPageReducer } from './slices/adminPageSlice/adminPageSlice'
import { errorMiddleware } from './middlewares/ErrorMidleware'
import { accountDetailsReducer } from './slices/AccounDetailsSlice/accountDetailsSlice'
import { usersStatisticSliceReducer } from './slices/usersStatisticSlice/usersStatisticSlice'
const rootReducer = combineReducers({
  tableLeadsReducer,
  notificationReducer,
  popupReducer,
  sidebarReducer,
  tableTeamManagementReducer,
  newFeedReducer,
  automationReducer,
  botManagementReducer,
  leadsNotificationReducer,
  adminPageReducer,
  accountDetailsReducer,
  usersStatisticSliceReducer,
})
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(errorMiddleware),
  devTools: process.env.REACT_APP_ENV !== 'prod',
});

export default store;
export type RootState = ReturnType<typeof rootReducer>;
