import { useMemo } from 'react';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useAppDispatch } from '../typedHooks';
import { usersStatisticSliceActions } from '../../slices/usersStatisticSlice/usersStatisticSlice';
import { exportStatistic, getCampaignStatistic, getGeneralStatistic } from '../../slices/usersStatisticSlice/thunks';

const rootActions = {
  ...usersStatisticSliceActions,
  getGeneralStatistic,
  getCampaignStatistic,
  exportStatistic
};

export const useUsersStatisticActions = () => {
  const dispatch = useAppDispatch();
  return useMemo(() => bindActionCreators(rootActions, dispatch), [dispatch]);
};