import * as React from 'react';
import { Box, Typography } from "@mui/material";
import DatePicker from "react-datepicker";
import style from './CalendarPicker.scss'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useTableLeadsData } from '../../utils/redux/storeHooks/dataHooks/useTableLeadsData';
import { useTableLeadsActions } from '../../utils/redux/storeHooks/actionsHooks/useTableLeadsActions';
import { FilterType } from '../../types/pages/dashboardLeadsPeoplePage/filterType';
import { useUsersStatisticData } from '../../utils/redux/storeHooks/dataHooks/useUsersStatisticData';
import { useUsersStatisticActions } from '../../utils/redux/storeHooks/actionsHooks/useUsersStatisticActions';

type Props = {
  filterType: FilterType;
  title?: string;
}

export default function CalendarPicker({ filterType, title }: Props) {
  const {
    dateInserted,
    dateLastCommented,
    dateLastReplied
  } = useTableLeadsData();

  const {
    setDateInserted,
    setDateLastCommented,
    setDateLastReplied
  } = useTableLeadsActions()

  const { dateRange } = useUsersStatisticData();
  const { setDateRange } = useUsersStatisticActions();

  const setSelectedInitialDate = () => {
    switch (filterType) {
      case FilterType.dateAdded:
        return {
          start: dateInserted.startDate ? new Date(dateInserted.startDate) : null,
          end: dateInserted.endDate ? new Date(dateInserted.endDate) : null
        }
      case FilterType.dateLastCommented:
        return {
          start: dateLastCommented.startDate ? new Date(dateLastCommented.startDate) : null,
          end: dateLastCommented.endDate ? new Date(dateLastCommented.endDate) : null
        }
      case FilterType.dateLastReplied:
        return {
          start: dateLastReplied.startDate ? new Date(dateLastReplied.startDate) : null,
          end: dateLastReplied.endDate ? new Date(dateLastReplied.endDate) : null
        }
      case FilterType.statisticPicker:
        return {
          start: dateRange.startDate ? new Date(dateRange.startDate) : null,
          end: dateRange.endDate ? new Date(dateRange.endDate) : null
        }
      default:
        return { start: null, end: null }
    }
  }

  const { start, end } = setSelectedInitialDate()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (range: any) => {
    const [startDate, endDate] = range;
    let start = startDate;
    let end = endDate;
    if (startDate) {
      start = new Date(startDate.getTime() - (startDate.getTimezoneOffset() * 60000)).toISOString();
    }
    if (endDate) {
      end = new Date(endDate.getTime() - (endDate.getTimezoneOffset() * 60000)).toISOString();
    }
    switch (filterType) {
      case FilterType.dateAdded:
        setDateInserted({ startDate: start, endDate: end })
        break;
      case FilterType.dateLastCommented:
        setDateLastCommented({ startDate: start, endDate: end })
        break;
      case FilterType.dateLastReplied:
        setDateLastReplied({ startDate: start, endDate: end })
        break;
      case FilterType.statisticPicker:
        setDateRange({ startDate: start, endDate: end })
        break;
    }
  }

  const getWrapperWidth = () => {
    switch (filterType) {
      case FilterType.dateAdded:
        return 'calc(var(--filter-width) - 48px)'
      case FilterType.dateLastCommented:
        return 'calc(var(--filter-width) - 48px)'
      case FilterType.dateLastReplied:
        return 'calc(var(--filter-width) - 48px)'
      case FilterType.statisticPicker:
        return '200px'
    }
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '4px',
    }}>
      {title && <Typography sx={{
        fontFamily: 'Kanit',
        color: '#6F7D9B',
        fontSize: '14px'
      }}>
        {title}
      </Typography>}
      <Box sx={{
        width: getWrapperWidth(),
      }}>
        <DatePicker
          wrapperClassName="datepicker-wrapper"
          selected={start}
          onChange={handleChange}
          startDate={start}
          endDate={end}
          selectsRange
          className={style.reactDatepicker}
          isClearable
          clearButtonClassName='clearButton'
          placeholderText='mm/dd/yyyy'
          icon={<CalendarMonthIcon sx={{
            color: '#6F7D9B',
          }} />}
          showIcon={!start || !end}
        />
      </Box>
    </Box>
  )
}