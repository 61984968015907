import React, { useEffect } from 'react';
import styles from "./UpgradePlan.module.scss";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Button } from '@material-ui/core';
import IconButton from '@mui/material/IconButton';
import { usePopupActions } from '../../../utils/redux/storeHooks/actionsHooks/usePopupActions';
import { Box, CircularProgress, Typography } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { SubscriptionPlan } from '../../../types/PPG/SubscriptionPlan';
import { useAccountDetailsData } from '../../../utils/redux/storeHooks/dataHooks/useAccountDetailsData';
import { useFreezePopUp } from '../../../utils/customHooks/useStackPopUp';
import { useAccountDetailsActions } from '../../../utils/redux/storeHooks/actionsHooks/useAccountDetailsActions';
import { Plan } from '../../../types/PPG/Plan';
import { getUserInformationFromlocalStorage } from '../../../utils/general/get_set_user_information';
import { PaymentFlow } from '../../../types/PPG/paymentFlow.enum';

export const PopUpUpgradePlan = () => {
  const { setPopupUpgradePlanOpen } = usePopupActions();
  const { activeSubscription, plans, isGetPlansRequest, isRequestUpgradePlan, planToUpgrade } = useAccountDetailsData();
  const { getPlans, upgradePlan } = useAccountDetailsActions();
  const { paymentFlow } = getUserInformationFromlocalStorage();

  const closePopUp = () => {
    setPopupUpgradePlanOpen(false)
  }

  useFreezePopUp()

  const onUpgradePlan = (plan: Plan) => {
    switch (paymentFlow) {
      case PaymentFlow.PPG:
        if (activeSubscription?.isTrial) {
          upgradePlan({ plan: plan.plan, subscriptionId: activeSubscription?.ppgId })
        } else {
          window.open(`https://meetings-eu1.hubspot.com/michael-pihosh/heet`, '_blank');
        }
        return;
      case PaymentFlow.INVOICE:
        window.open(`https://meetings-eu1.hubspot.com/michael-pihosh/heet`, '_blank');
        return;
    }
  }

  useEffect(() => {
    getPlans()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getButtonTitleAndStyle = (enumTitle: SubscriptionPlan) => {
    if (activeSubscription?.plan === enumTitle) {
      return { title: 'Current plan', styles: styles.content__actionsContainer_button_current };
    }

    if (activeSubscription?.isTrial) {
      return { title: 'Upgrade', styles: styles.content__actionsContainer_button_regular };
    }

    return { title: 'Request Call', styles: styles.content__actionsContainer_button_regular }
  }

  const getCardStyleAndText = (enumTitle: SubscriptionPlan) => {
    const { title, styles } = getButtonTitleAndStyle(enumTitle)
    switch (enumTitle) {
      case SubscriptionPlan.STARTER:
        return {
          styles,
          title,
          img: '/starter.svg'
        }
      case SubscriptionPlan.STARTER_EXTENDED:
        return {
          styles,
          title,
          img: '/starter_extended.svg'
        }
      case SubscriptionPlan.PRO:
        return {
          styles,
          title,
          img: '/pro.svg'
        }
    }
  }

  return (
    <Box className={styles.popup}>
      <Box className={styles.header}>
        <Box className={styles.header__title}>
          Simple pricing for your business
        </Box>
        <Box className={styles.header__subTitle}>
          Plans that are carefully crafted to suit your business
        </Box>
        <IconButton
          color="default"
          onClick={closePopUp}
          className={styles.header__closeButton}
        >
          <CloseRoundedIcon sx={{ fill: 'rgba(111, 125, 155, 1)' }} />
        </IconButton>
      </Box>
      {isGetPlansRequest ? (
        <Box className={styles.loaderContainer}>
          <CircularProgress className={styles.loaderContainer__loader} />
        </Box>
      ) : (
        <Box className={styles.content}>
          {plans.map(plan => {
            const cardStyle = getCardStyleAndText(plan.plan)
            return (
              <Box key={plan.name} className={`${styles.content__card} ${plan.isBestDeal && styles.content__card__bestDeal}`} boxShadow={2}>
                <Box className={styles.content__cardHeader}>
                  <img src={cardStyle.img} alt='' />
                  <Box className={styles.content__cardHeader_cardNameContainer}>
                    <Typography className={styles.content__cardHeader_cardName}>
                      {plan.name}
                    </Typography>
                    <Typography className={styles.content__cardHeader_cardLeads}>
                      {`${plan.leadsCount.toLocaleString('ru-RU')} leads`}
                    </Typography>
                  </Box>
                </Box>

                {activeSubscription?.isTrial
                  ? (<Box className={styles.content__cardPriceContainer}>
                    <Typography className={styles.content__cardPriceContainer_cardPriceMain}>
                      {`$${plan.price}`}
                    </Typography>
                    <Typography className={styles.content__cardPriceContainer_cardPriceSyb}>
                      /monthly
                    </Typography>
                  </Box>)
                  : <Box sx={{ marginTop: '12px' }} />
                }

                <Box className={styles.content__actionsContainer}>
                  <Button
                    variant="contained"
                    className={`${styles.content__actionsContainer_button} ${cardStyle.styles}`}
                    disabled={activeSubscription?.plan === plan.plan || isRequestUpgradePlan}
                    onClick={() => onUpgradePlan(plan)}
                  >
                    {isRequestUpgradePlan && planToUpgrade === plan.plan
                      ? <CircularProgress sx={{ color: 'inherit' }} size={30} />
                      : cardStyle.title}
                  </Button>
                </Box>

                <Box className={styles.content__featuresContainer}>
                  <Box className={
                    `${styles.content__featuresContainer_feature} ${styles.content__featuresContainer_featureLeads}`
                  }>
                    <PeopleIcon sx={{ color: 'rgba(107, 77, 175, 1)', fontSize: '24px' }} />
                    {`${plan.leadsCount} LinkedIn leads to monitor`}
                  </Box>
                  {plan.features.map(feature => (
                    <Box key={feature} className={styles.content__featuresContainer_feature}>
                      <CheckCircleIcon sx={{ color: 'rgba(107, 77, 175, 1)', fontSize: '24px' }} />
                      {feature}
                    </Box>
                  ))}
                  {plan.specialFeatures && <Box className={styles.content__specialfeaturesContainer}>
                    {plan.specialFeatures?.map(feature => (
                      <Box key={feature} className={styles.content__featuresContainer_feature}>
                        <CheckCircleIcon sx={{ color: 'rgba(107, 77, 175, 1)', fontSize: '24px' }} />
                        {feature}
                      </Box>
                    ))}
                  </Box>}

                </Box>
              </Box>
            )
          })}
        </Box>
      )
      }
    </Box >
  );
};
