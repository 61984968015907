import { getRequest, postRequest } from "../../../general/https";
import { checkErrorStatus } from "../../../general/checkErrorStatus";
import {
    CancelPlanPayload,
    CancelPlanResponse,
    GetInvoicesHistoryPayload,
    GetInvoicesHistoryResponse,
    GetSubscriptionPayload,
    GetSubscriptionResponse, PayNowParams,
    PayNowResponse,
    RestorePasswordPayload,
    RestorePasswordResponse,
    SendEmailToChangePaymentDetailsPayload,
    SendEmailToChangePaymentDetailsResponse,
    SignInPayload,
    SignInResponse,
    UpgradePlanPayload,
    UpgradePlanResponse
} from "./types";
import { createAsyncThunkCheckSubscription } from "../../middlewares/thunkCondition";
import { cleanUpAllStorage, deleteAuthCookies, getUserInformationFromlocalStorage } from "../../../general/get_set_user_information";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Plan } from "../../../../types/PPG/Plan";

const authMainUrl = '/auth'
const PPG_MAIN_URL = '/ppg-subscription'

export const signIn = createAsyncThunkCheckSubscription<SignInResponse, SignInPayload>(
  'accountDetailsSlice/signIn',
  async (payload, {rejectWithValue}) => {
    try {
      const response = await postRequest(`${authMainUrl}/signIn`, payload, true)
      checkErrorStatus(response)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const getSubscriptionDetails = createAsyncThunk<GetSubscriptionResponse, GetSubscriptionPayload>(
  'accountDetailsSlice/getSubscriptionDetails',
  async (_, {rejectWithValue}) => {
    try {
      const {id} = getUserInformationFromlocalStorage()
      const response = await getRequest(`${PPG_MAIN_URL}/${id}/details?latestOnly=true`, false)
      checkErrorStatus(response)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const restorePassword = createAsyncThunkCheckSubscription<RestorePasswordResponse, RestorePasswordPayload>(
  'accountDetailsSlice/restorePassword',
  async (payload, {rejectWithValue}) => {
    try {
      const response = await postRequest(`${authMainUrl}/restore-pass`, payload, false)
      checkErrorStatus(response)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const confirmRestorePassword = createAsyncThunkCheckSubscription<RestorePasswordResponse, RestorePasswordPayload>(
  'accountDetailsSlice/confirmRestorePassword',
  async (payload, {rejectWithValue}) => {
    try {
      const response = await postRequest(`${authMainUrl}/restore-pass-confirm`, payload, true)
      checkErrorStatus(response)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const getInvoicesHistory = createAsyncThunk<GetInvoicesHistoryResponse, GetInvoicesHistoryPayload>(
  'accountDetailsSlice/getInvoicesHistory',
  async ({
    skip,
    take,
    sortByDate,
    customerEmail
  }, {rejectWithValue}) => {
    const REACT_APP_ENV = process.env.REACT_APP_ENV
    const includeTestOrders = REACT_APP_ENV === 'prod' ? false : true;
    try {
      const response = await getRequest(`${PPG_MAIN_URL}/invoices/?take=${take}&skip=${skip}&sortByDate=${sortByDate}&includeTestOrders=${includeTestOrders}&customerEmail=${customerEmail}`,
        false)
      checkErrorStatus(response)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const upgradePlan = createAsyncThunkCheckSubscription<UpgradePlanResponse, UpgradePlanPayload>(
  'accountDetailsSlice/upgradePlan',
  async ({subscriptionId, plan}, {rejectWithValue}) => {    
    try {
      const response = await postRequest(`${PPG_MAIN_URL}/${subscriptionId}/change-plan`, {plan}, false)
      checkErrorStatus(response)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const cancelPlan = createAsyncThunk<CancelPlanResponse, CancelPlanPayload>(
  'accountDetailsSlice/cancelPlan',
  async ({reasonText, subscriptionId}, {rejectWithValue}) => {
    try {
      console.log(subscriptionId)
      const response = await postRequest(`${PPG_MAIN_URL}/${subscriptionId}/terminate`, {reasonText}, false)
      checkErrorStatus(response)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const payNow = createAsyncThunk<PayNowResponse, PayNowParams>(
    'accountDetailsSlice/payNow',
    async ({userId}, {rejectWithValue}) => {
      try {
        const url = `${PPG_MAIN_URL}/${userId}/pay-now`;
        const response = await postRequest(url, {}, false);

        checkErrorStatus(response);

        return response.data as PayNowResponse;
      } catch (error) {
        return rejectWithValue(error.message || 'Unexpected error occurred');
      }
    }
);
export const logOut = createAsyncThunk (
  'accountDetailsSlice/logOut',
  async (_, {rejectWithValue}) => {
    try {
      await getRequest(`${authMainUrl}/signout`, false)
      cleanUpAllStorage();
      deleteAuthCookies()
      localStorage.removeItem('token');
      window.location.href = '/signIn'
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const getPlans = createAsyncThunk<Plan[]>(
  'accountDetailsSlice/getPlans',
  async (_, {rejectWithValue}) => {
    try {
      const response = await getRequest(`${PPG_MAIN_URL}/plans`, false)
      checkErrorStatus(response)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const sendEmailToChangePaymentDetails = createAsyncThunk<
  SendEmailToChangePaymentDetailsResponse, 
  SendEmailToChangePaymentDetailsPayload
>(
  'accountDetailsSlice/sendEmailToChangePaymentDetails',
  async (payload, {rejectWithValue}) => {
    try {
      const response = await postRequest(`${PPG_MAIN_URL}/send-login-link`, payload, false)
      checkErrorStatus(response)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)


