import { HeadCell } from "../../../types/pages/dashboardLeadsPeoplePage/headCell";

export const headCellsBill: readonly HeadCell[] = [
  {
    id: 'Date',
    numeric: false,
    disablePadding: false,
    label: 'Date',
    disableSorting: false,
    showToolTip: false,
    textForToolTip: '',
    maxWidth: 100,
    minWidth: 100,
  },
  {
    id: 'Payment Method',
    numeric: false,
    disablePadding: false,
    label: 'Payment Method',
    disableSorting: false,
    showToolTip: false,
    textForToolTip: '',
    maxWidth: 150,
    minWidth: 150,
  },
  
  {
    id: 'Status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
    disableSorting: true,
    showToolTip: false,
    textForToolTip: '',
    maxWidth: 100,
    minWidth: 100,
  },
  {
    id: 'Download',
    numeric: true,
    disablePadding: false,
    label: 'Link',
    disableSorting: true,
    showToolTip: false,
    textForToolTip: '',
    maxWidth: 50,
    minWidth: 50,
  }
  
];