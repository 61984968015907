import React from "react";
import styles from "../AccountDetails.module.scss";
import { Box, Typography } from "@mui/material"
import { TableBills } from "./tableBills/TableBills";

export const BillingHistory = () => {
  return (
    <Box className={styles.accountDetails__billingHistoryContainer}>
      <Typography className={styles.accountDetails__billingHistoryContainer__title}>
        Billing History
      </Typography>
      <TableBills />
    </Box>
  )
}