import React from 'react';
import styles from "./BlockingModal.module.scss";
import { Box, Typography } from '@mui/material';
import { MainActionButton } from '../../actionButton/MainActionButton';
import { useFreezePopUp } from '../../../utils/customHooks/useStackPopUp';

export const PopUpInvoiceSubscriptionEnd = () => {
  const onRequestCall = () => {
    window.open(`https://meetings-eu1.hubspot.com/michael-pihosh/heet`, '_blank');
  }
  useFreezePopUp();


  return (
    <Box className={`${styles.popup} ${styles.popup__invoiceSubscriptionEnd}`}>
      <Box className={styles.header}>
        <img src='/subscriptoin_lock.svg' alt='lock' className={styles.header__img} />
        <Typography className={styles.header__title}>
          Uh-oh, looks like your subscription has ended...
        </Typography>
        <Typography className={styles.header__subTitle} sx={{ textAlign: 'center', padding: '12px 36px' }}>
          Please reach out to our support team to get back into the app and enjoy your favorite features again. 😊
        </Typography>
      </Box>
      <Box className={styles.footer}>
        <MainActionButton
          title='Request a call'
          borderRadius='2px'
          actionOnClick={onRequestCall}
          width={140}
        />
      </Box>
    </Box>
  );
};
