import { Box, Typography } from "@mui/material";
import React from "react";
import styles from "../UsersStatistic.module.scss";
import CalendarPicker from "../../../components/calendarPicker/CalendarPicker";
import { FilterType } from "../../../types/pages/dashboardLeadsPeoplePage/filterType";
import { MainActionButton } from "../../../components/actionButton/MainActionButton";
import LogoutIcon from '@mui/icons-material/Logout';
import { useUsersStatisticActions } from "../../../utils/redux/storeHooks/actionsHooks/useUsersStatisticActions";
import { useUsersStatisticData } from "../../../utils/redux/storeHooks/dataHooks/useUsersStatisticData";
import { StatisticType } from "../../../types/pages/usersStatistic/usersStatistic";


export const UsersStatisticHeader = () => {
  const { exportStatistic } = useUsersStatisticActions();
  const { dateRange, isExportRequest } = useUsersStatisticData();

  const onExport = () => {
    exportStatistic({
      dateRange,
      statisticType: StatisticType.GENERAL
    })
  }

  return (
    <Box className={styles.usersStatistic__header}>
      <Typography className={styles.usersStatistic__header__mainTitle}>
        Overview
      </Typography>
      <Box className={styles.usersStatistic__header__actions}>
        <CalendarPicker filterType={FilterType.statisticPicker} />
        <MainActionButton
          title="Export"
          actionOnClick={onExport}
          isDisabled={isExportRequest}
          isLoading={isExportRequest}
          backgroundMainColor="rgba(224, 232, 249, 1)"
          borderRadius="4px"
          fontColor="rgba(25, 31, 46, 1)"
          icon={<LogoutIcon sx={{ transform: 'rotate(-90deg)', fontSize: '18px' }} />}
          fontSize={16}
        />
      </Box>
    </Box>
  )
}

