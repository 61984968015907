import React from "react";
import styles from "../AccountDetails.module.scss";
import { Box, Divider, Typography, Zoom } from "@mui/material"
import { useAccountDetailsData } from "../../../utils/redux/storeHooks/dataHooks/useAccountDetailsData";
import { Payment_status } from "../../../types/PPG/Payment_status";
import TimerIcon from '@mui/icons-material/Timer';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import { SubscriptionPlan } from "../../../types/PPG/SubscriptionPlan";
import { UserPermissionList } from "../../../types/PPG/UserPermissionList";
import { PPGSubscriptionStatus } from "../../../types/PPG/PPGSubscriptionStatus";
import { AccountDetailsActions } from "./AccountDetailsActions";
import { TooltipApp } from "../../../components/tooltipApp/TooltipApp";
import { isFirstPaymentProcessing } from "../../../utils/subscription/common";


export const SubscriptionSummary = () => {
  const { activeSubscription, invoices } = useAccountDetailsData();

  const isSubscribeDisabled = isFirstPaymentProcessing(activeSubscription, invoices)

  const isSubscriptionAlreadyCanceled = activeSubscription?.permission === UserPermissionList.ALLOW_APP_ACCESS
    && activeSubscription?.status === PPGSubscriptionStatus.Terminated;

  const getPaymentStatusDefinition = (payment_status: Payment_status) => {
    switch (payment_status) {
      case Payment_status.PENDING:
        return {
          text: Payment_status.PENDING.toLocaleLowerCase(),
          icon: <TimerIcon sx={{ color: 'rgba(177, 208, 232, 1)', fontSize: 26 }} />
        }
      case Payment_status.CONFIRMED:
        return {
          text: Payment_status.CONFIRMED.toLocaleLowerCase(),
          icon: <CheckIcon sx={{ color: 'rgba(191, 232, 177, 1)', fontSize: 26 }} />

        }
      case Payment_status.FAILED:
        return {
          text: Payment_status.FAILED.toLocaleLowerCase(),
          icon: <ErrorIcon sx={{ color: 'rgba(255, 183, 163, 1)', fontSize: 26 }} />

        }
      default: return {
        text: Payment_status.CONFIRMED.toLocaleLowerCase(),
        icon: <CheckIcon sx={{ color: 'rgba(191, 232, 177, 1)', fontSize: 26 }} />
      }
    }
  }

  const getPlanText = () => {
    switch (activeSubscription.plan) {
      case SubscriptionPlan.STARTER:
        return 'Starter'
      case SubscriptionPlan.STARTER_EXTENDED:
        return 'Starter Extended'
      case SubscriptionPlan.PRO:
        return 'Pro'
    }
  }

  const paymentStatusDefinition = getPaymentStatusDefinition(activeSubscription?.paymentStatus);

  if (isSubscribeDisabled) {
    return (
      <>
        <Box className={styles.accountDetails__subscriptionSummary}>
          <Typography className={styles.accountDetails__subscriptionSummary__title}>
            Subscription Summary
          </Typography>
          <Box className={styles.accountDetails__subscriptionSummary__noSubscription}>
            Your payment is processing. Thank you for your patience! Access will be provided within a few minutes.
          </Box>
        </Box>
        <Divider sx={{ height: '2px', width: '100%' }} />
        <AccountDetailsActions />
      </>
    )
  }

  return (
    <>
      <Box className={styles.accountDetails__subscriptionSummary}>
        <Typography className={styles.accountDetails__subscriptionSummary__title}>
          Subscription Summary
        </Typography>

        {activeSubscription?.isActive ? (<Box className={styles.accountDetails__subscriptionSummary__infoContainer}>
          <Box className={styles.accountDetails__subscriptionSummary__info}>
            <Typography className={styles.accountDetails__subscriptionSummary__info__name}>
              Plan
            </Typography>
            <Typography className={styles.accountDetails__subscriptionSummary__info__definition}>
              {activeSubscription.isTrial ? `${getPlanText()} - trial` : getPlanText()}
            </Typography>
          </Box>
          <Box className={styles.accountDetails__subscriptionSummary__info}>
            <Typography className={styles.accountDetails__subscriptionSummary__info__name}>
              {isSubscriptionAlreadyCanceled ? `End date` : `Next payment`}
            </Typography>
            <Typography className={styles.accountDetails__subscriptionSummary__info__definition}>
              {activeSubscription.nextPayment || ''}
            </Typography>
          </Box>
          {!isSubscriptionAlreadyCanceled && <Box className={styles.accountDetails__subscriptionSummary__info}>
            <Typography className={styles.accountDetails__subscriptionSummary__info__name}>
              Billed
            </Typography>
            <Typography className={styles.accountDetails__subscriptionSummary__info__definition}>
              Monthly - Auto-renewal
            </Typography>
          </Box>}
          {activeSubscription?.paymentStatus === Payment_status.PENDING
            ? (
              <TooltipApp TransitionComponent={Zoom} placement="left" title={
                <>
                  <span className={styles.toolTipBody}>
                    {`Payment may take a few minutes to process. Please wait and then click the 'Refresh Payment Status' button below.`}
                  </span>
                </>
              }>
                <Box className={styles.accountDetails__subscriptionSummary__info}>
                  <Typography className={styles.accountDetails__subscriptionSummary__info__name}>
                    Payment status
                  </Typography>
                  <Box className={styles.accountDetails__subscriptionSummary__info__definition__status}>
                    {paymentStatusDefinition.text}
                    {paymentStatusDefinition.icon}
                  </Box>
                </Box>
              </TooltipApp>
            )
            : (
              <Box className={styles.accountDetails__subscriptionSummary__info}>
                <Typography className={styles.accountDetails__subscriptionSummary__info__name}>
                  Payment status
                </Typography>
                <Box className={styles.accountDetails__subscriptionSummary__info__definition__status}>
                  {paymentStatusDefinition.text}
                  {paymentStatusDefinition.icon}
                </Box>
              </Box>
            )
          }
          <Box className={styles.accountDetails__subscriptionSummary__info}>
            <Typography className={styles.accountDetails__subscriptionSummary__info__name}>
              Subscription status
            </Typography>
            <Box className={styles.accountDetails__subscriptionSummary__info__definition__status}>
              {isSubscriptionAlreadyCanceled ? 'Cancel' : activeSubscription.status}
            </Box>
          </Box>
        </Box>) : (
          <Box className={styles.accountDetails__subscriptionSummary__noSubscription}>{`You don't have an active subscription.`}</Box>
        )}
      </Box >
      <Divider sx={{ height: '2px', width: '100%' }} />
      <AccountDetailsActions />
    </>
  )
}
