import React from 'react';
import styles from "./BlockingModal.module.scss";
import { Box, Typography } from '@mui/material';
import { useAccountDetailsData } from '../../../utils/redux/storeHooks/dataHooks/useAccountDetailsData';
import { getUserInformationFromlocalStorage } from '../../../utils/general/get_set_user_information';
import { MainActionButton } from '../../actionButton/MainActionButton';
import { Link } from 'react-router-dom';
import { isFirstPaymentProcessing, openSubscriptionLink } from "../../../utils/subscription/common";
import { useFreezePopUp } from '../../../utils/customHooks/useStackPopUp';

export const PopUpNewSubscription = () => {
  const { activeSubscription, invoices } = useAccountDetailsData()
  const { paymentFlow } = getUserInformationFromlocalStorage();
  const isSubscribeDisabled = isFirstPaymentProcessing(activeSubscription, invoices)
  useFreezePopUp();


  const onRequestFirstSubscription = async () => {
    await openSubscriptionLink(paymentFlow, activeSubscription);
  };

  return (
    <Box className={`${styles.popup} ${styles.popup__newSubscription}`}>
      <Box className={styles.header}>
        <img src='/subscriptoin_lock.svg' alt='lock' className={styles.header__img} />
        <Typography className={styles.header__title}>
          Welcome aboard!  🎉
        </Typography>
        <Typography className={styles.header__subTitle} sx={{ padding: '24px 36px', textAlign: 'center' }}>
          We’re glad to have you here! Subscribe to unlock all features and get the most out of the app. We’re sure you’ll love it! 😊
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', gap: '48px' }}>
        <MainActionButton
          title='Subscribe'
          actionOnClick={onRequestFirstSubscription}
          borderRadius='2px'
          isDisabled={isSubscribeDisabled}
        />
      </Box>

      <Box>
        <Typography sx={{ fontSize: '12px', fontFamily: 'Kanit', fontWeight: 300, color: '#6f7d9b', marginTop: '12px' }}>
          *Subscription processing may take a few minutes. Please wait a moment, then refresh the payment status on <Link to='/account-details'>account details page.</Link>
        </Typography>

      </Box>
    </Box>
  );
};
