import { SubscriptionI } from "../../types/PPG/SubscriptionI";
import { CheckoutUrlBuilder } from "../general/build-checkout-url";
import { getUserInformationFromlocalStorage } from "../general/get_set_user_information";
import {postRequest} from "../general/https";

export const createLink = (activeSubscription: SubscriptionI) => {
  const { id, firstName, lastName, email } = getUserInformationFromlocalStorage();

  const builder = new CheckoutUrlBuilder(
    'https://store.payproglobal.com/checkout',
    process.env.REACT_APP_ENV
  );

  builder.addParam('billing-first-name', firstName);
  builder.addParam('billing-last-name', lastName);
  builder.addParam('x-userid', `${id}`);
  builder.addParam('billing-email', email);
  builder.addParam('paypal-email', email);
  if (activeSubscription) {
    builder.addParam('products[1][skip-trial-period]', true)
  }
  // hardcoded
  builder.setHardcodedParam();

  const checkoutUrl = builder.build();
  return checkoutUrl;
}

export const createLinkThroughApi = async (activeSubscription: SubscriptionI) => {
  const { id, firstName, lastName, email } = getUserInformationFromlocalStorage();


  const dynamicParams = {
    'billing-first-name': firstName,
    'billing-last-name': lastName,
    'x-userid': `${id}`,
    'billing-email': email,
    'products[1][skip-trial-period]': activeSubscription ? true : false,
  };

  try {
     const response = await postRequest(
        '/ppg-subscription/generate-checkout-link',
        { dynamicParams },
        false
    );

     const checkoutUrl = response.data.link;
    return checkoutUrl;
  } catch (error) {
    console.error('Error generating checkout link:', error);
    throw new Error('Unable to generate checkout link');
  }
};
