import React from "react";
import styles from "../AccountDetails.module.scss";

import { useAccountDetailsActions } from "../../../utils/redux/storeHooks/actionsHooks/useAccountDetailsActions";
import { useAccountDetailsData } from "../../../utils/redux/storeHooks/dataHooks/useAccountDetailsData";
import { usePopupActions } from "../../../utils/redux/storeHooks/actionsHooks/usePopupActions";
import { MainActionButton } from "../../../components/actionButton/MainActionButton";
import { Payment_status } from "../../../types/PPG/Payment_status";
import { getUserInformationFromlocalStorage } from "../../../utils/general/get_set_user_information";
import { PaymentFlow } from "../../../types/PPG/paymentFlow.enum";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { PPGSubscriptionStatus } from "../../../types/PPG/PPGSubscriptionStatus";
import { Box, Zoom } from "@mui/material";
import { useSidebarData } from "../../../utils/redux/storeHooks/dataHooks/useSidebarData";
import AddCardIcon from '@mui/icons-material/AddCard';
import { PayNowParams } from "../../../utils/redux/slices/AccounDetailsSlice/types";
import { isFirstPaymentProcessing, openSubscriptionLink } from "../../../utils/subscription/common";
import { UserPermissionList } from "../../../types/PPG/UserPermissionList";
import { TooltipApp } from "../../../components/tooltipApp/TooltipApp";

export const SubscriptionActions = () => {
  const { activeSubscription, isGetSubscriptionDetailsRequest, isCancelPlanRequest, isRequestToSendEmailUpdatePaymentDetails, invoices } = useAccountDetailsData();
  const { getSubscriptionDetails, sendEmailToChangePaymentDetails, payNow } = useAccountDetailsActions();
  const { setPopupUpgradePlanOpen, setPopUpCancelSubscriptionOpen } = usePopupActions();
  const { isDataLoading: isGetingSideBarData } = useSidebarData();
  const { paymentFlow, email, id } = getUserInformationFromlocalStorage();
  const isSubscribeDisabled = isFirstPaymentProcessing(activeSubscription, invoices);

  const onSubscribe = async () => {
    await openSubscriptionLink(paymentFlow, activeSubscription);
  };

  const onUpgradePlan = () => {
    setPopupUpgradePlanOpen(true)
  }

  const onUpdatePaymentStatus = () => {
    switch (paymentFlow) {
      case PaymentFlow.PPG:
        getSubscriptionDetails({ isUpdateMethod: true, isAccountDetailsPage: true })
        return;
      case PaymentFlow.INVOICE:
        window.open(`https://meetings-eu1.hubspot.com/michael-pihosh/heet`, '_blank');
        return;
    }
  }

  const onCancelPlan = () => {
    switch (paymentFlow) {
      case PaymentFlow.PPG:
        setPopUpCancelSubscriptionOpen(true)
        return;
      case PaymentFlow.INVOICE:
        window.open(`https://meetings-eu1.hubspot.com/michael-pihosh/heet`, '_blank');
        return;
    }
  }

  const onUpdatePaymentDetails = async () => {
    sendEmailToChangePaymentDetails({
      customerEmail: email
    })
  }

  const onPayNow = async () => {
    payNow({ userId: id } as PayNowParams);
  };

  const getCancelSubTooltipText = () => {
    if (activeSubscription?.isTrial) {
      return `You’ve already canceled your free subscription:( You can continue using the app until your free trial ends.`
    }

    return `You've already canceled your subscription:( Access to the app will remain available until the end of the paid period.`
  }

  if (!activeSubscription && (isGetSubscriptionDetailsRequest || isGetingSideBarData)) {
    return <Box />
  }

  if (paymentFlow === PaymentFlow.INVOICE) {
    if (!activeSubscription) {
      return (
        <MainActionButton
          title="Subscribe"
          width={200}
          borderRadius="2px"
          actionOnClick={() => onSubscribe()}
        />
      )
    }

    return (
      <>
        <MainActionButton
          title="Upgrade Subscription"
          width={200}
          borderRadius="2px"
          actionOnClick={() => onUpgradePlan()}
        />
        <MainActionButton
          title="Cancel My Subscription"
          backgroundMainColor="rgba(224, 232, 249, 1)"
          fontColor="rgba(25, 31, 46, 1)"
          borderRadius="2px"
          width={200}
          fontWeight={400}
          actionOnClick={onCancelPlan}
          isDisabled={isCancelPlanRequest}
          isLoading={isCancelPlanRequest}
        />
      </>
    )
  }

  if (paymentFlow === PaymentFlow.PPG) {
    if (!activeSubscription || (activeSubscription?.status === PPGSubscriptionStatus.Terminated && activeSubscription?.permission === UserPermissionList.DENY_APP_ACCESS)) {
      return <MainActionButton
        title="Subscribe"
        width={200}
        borderRadius="2px"
        actionOnClick={() => onSubscribe()}
        isDisabled={isSubscribeDisabled}
      />
    }

    if (activeSubscription?.status === PPGSubscriptionStatus.Terminated && activeSubscription?.permission === UserPermissionList.ALLOW_APP_ACCESS) {
      return (
        <TooltipApp TransitionComponent={Zoom} placement="left" title={
          <>
            <span className={styles.toolTipBody}>
              {getCancelSubTooltipText()}
            </span>
          </>
        }>
          <Box>
            <MainActionButton
              title="Cancel My Subscription"
              borderRadius="2px"
              width={200}
              fontWeight={400}
              isDisabled
            />
          </Box>
        </TooltipApp>
      )
    }

    if (activeSubscription?.paymentStatus === Payment_status.CONFIRMED) {
      return (
        <>
          <MainActionButton
            title="Update Payment Details"
            icon={<AddCardIcon />}
            fontSize={12}
            width={200}
            actionOnClick={onUpdatePaymentDetails}
            isLoading={isRequestToSendEmailUpdatePaymentDetails}
            isDisabled={isRequestToSendEmailUpdatePaymentDetails}
            borderRadius="2px"
          />
          <MainActionButton
            title="Upgrade Subscription"
            width={200}
            borderRadius="2px"
            actionOnClick={() => onUpgradePlan()}
          />
          <MainActionButton
            title="Cancel My Subscription"
            backgroundMainColor="rgba(224, 232, 249, 1)"
            fontColor="rgba(25, 31, 46, 1)"
            borderRadius="2px"
            width={200}
            fontWeight={400}
            actionOnClick={onCancelPlan}
            isDisabled={isCancelPlanRequest}
            isLoading={isCancelPlanRequest}
          />
        </>
      )
    }
    if (activeSubscription?.paymentStatus === Payment_status.PENDING) {
      return <MainActionButton
        title="Refresh Payment Status"
        icon={<RestartAltIcon />}
        fontSize={12}
        width={200}
        actionOnClick={onUpdatePaymentStatus}
        isLoading={isGetSubscriptionDetailsRequest}
        isDisabled={isGetSubscriptionDetailsRequest}
        borderRadius="2px"
      />
    }

    if (activeSubscription?.paymentStatus === Payment_status.FAILED) {
      return (
        <>
          <MainActionButton
            title="Update Payment Details"
            icon={<AddCardIcon />}
            width={225}
            actionOnClick={onUpdatePaymentDetails}
            isLoading={isRequestToSendEmailUpdatePaymentDetails}
            isDisabled={isRequestToSendEmailUpdatePaymentDetails}
            borderRadius="2px"
          />
          <MainActionButton
            title="Refresh Payment Status"
            icon={<RestartAltIcon />}
            width={225}
            actionOnClick={onUpdatePaymentStatus}
            isLoading={isGetSubscriptionDetailsRequest}
            isDisabled={isGetSubscriptionDetailsRequest}
            borderRadius="2px"
          />
          <MainActionButton
            title="Pay Now"
            width={200}
            borderRadius="2px"
            actionOnClick={onPayNow}
          />
          <MainActionButton
            title="Cancel My Subscription"
            backgroundMainColor="rgba(224, 232, 249, 1)"
            fontColor="rgba(25, 31, 46, 1)"
            borderRadius="2px"
            width={200}
            fontWeight={400}
            actionOnClick={onCancelPlan}
            isDisabled={isCancelPlanRequest}
            isLoading={isCancelPlanRequest}
          />
        </>
      )
    }
  }

  return <Box />
}

