import { Box, LinearProgress, Typography } from "@mui/material";
import React from "react";

type Props = {
  value: number;
  backColor?: string;
  progressColor?: string;
  height?: number;
  borderRadius?: number;
}

export const DeterminateLinearProgress = ({ value, backColor, progressColor, height, borderRadius }: Props) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress
          variant="determinate"
          value={value}
          style={{ backgroundColor: backColor || 'rgba(224, 232, 249, 0.4)' }}
          sx={{
            height: height || 10,
            borderRadius: borderRadius || 5,
            '& .MuiLinearProgress-bar': {
              backgroundColor: progressColor || 'rgba(150, 45, 255, 1)',
            },
          }}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          sx={{ color: 'black', fontFamily: 'Inter' }}
        >
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  )
}

