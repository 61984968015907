import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AccountDetailsInitialState } from './types';
import {
  cancelPlan,
  confirmRestorePassword,
  getInvoicesHistory,
  getPlans,
  getSubscriptionDetails,
  payNow,
  restorePassword,
  sendEmailToChangePaymentDetails,
  signIn,
  upgradePlan
} from './thunks';
import { Payment_status } from '../../../../types/PPG/Payment_status';
import { AccessAppStatuses } from '../../../../types/PPG/AccessAppStatuses.enum';
import { getBlockedModalName } from '../../../pages/accountDetails/getBlockedModalName';
import { PPGSubscriptionStatus } from '../../../../types/PPG/PPGSubscriptionStatus';

const initialState: AccountDetailsInitialState = {
  //Auth requests
  isSendingRestorePasswordEmail: false,
  isSingInRequest: false,
  isConfirmRestorePasswordRequest: false,
  isEmailRestorePasswordSent: false,
  //Get subscription
  accessAppStatuses: AccessAppStatuses.SUBSCRIPTION_ACTIVE,
  isGetSubscriptionDetailsRequest: false,
  activeSubscription: null,
  //Get plans
  isGetPlansRequest: false,
  plans: [],
  //Upgrade plan
  isRequestUpgradePlan: false,
  planToUpgrade: null,
  //Get invoices
  invoices: [],
  isGetInvoicesRequest: false,
  invoicePage: 0,
  totalInvoicesCount: 0,
  //Cancel plan
  isCancelPlanRequest: false,
  //Send email update payment details
  isRequestToSendEmailUpdatePaymentDetails: false,
  isPayNowRequest: false
};

export const accountDetailsSlice = createSlice({
  name: 'accountDetailsSlice',
  initialState,
  reducers: {
    setIsEmailRestorePasswordSent: (state, { payload }: PayloadAction<boolean>) => {
      state.isEmailRestorePasswordSent = payload;
    },
    setInvoicePage: (state, { payload }: PayloadAction<number>) => {
      state.invoicePage = payload;
    },
    setAccessAppStatus: (state, { payload }: PayloadAction<AccessAppStatuses>) => {
      state.accessAppStatuses = payload;
    },
       
  },
  extraReducers: (builder) => {
    builder.addCase(payNow.pending, (state) => {
      state.isPayNowRequest = true;
    })
    builder.addCase(payNow.fulfilled, (state, action) => {
      state.isPayNowRequest = false;
      state.activeSubscription.paymentStatus = Payment_status.PENDING;
    })
    builder.addCase(payNow.rejected, (state, action) => {
      state.isPayNowRequest = false;
    })
    //SignIn
    builder.addCase(signIn.pending, (state) => {
      state.isSingInRequest = true;
    })
    builder.addCase(signIn.fulfilled, (state, action) => {
        state.isSingInRequest = false;
    })
    builder.addCase(signIn.rejected, (state, action) => {
      state.isSingInRequest = false;
    })
    //Restore password
    builder.addCase(restorePassword.pending, (state) => {
      state.isSendingRestorePasswordEmail = true;
    })
    builder.addCase(restorePassword.fulfilled, (state, action) => {
      state.isEmailRestorePasswordSent = true;
      state.isSendingRestorePasswordEmail = false;
    })
    builder.addCase(restorePassword.rejected, (state, action) => {
      state.isSendingRestorePasswordEmail = false;
    })
    //Confir restore password
    builder.addCase(confirmRestorePassword.pending, (state) => {
      state.isConfirmRestorePasswordRequest = true;
    })
    builder.addCase(confirmRestorePassword.fulfilled, (state, action) => {
        state.isConfirmRestorePasswordRequest = false;
    })
    builder.addCase(confirmRestorePassword.rejected, (state, action) => {
      state.isConfirmRestorePasswordRequest = false;
    })
    //Get subscription details
    builder.addCase(getSubscriptionDetails.pending, (state) => {
      state.isGetSubscriptionDetailsRequest = true;
    })
    builder.addCase(getSubscriptionDetails.fulfilled, (state, action) => {
      state.isGetSubscriptionDetailsRequest = false;
      const currentSubscription = action.payload.subscriptionList[0] || null;
      state.activeSubscription = currentSubscription;
      const modalName = getBlockedModalName(currentSubscription);

      if(!modalName) {
        state.accessAppStatuses = AccessAppStatuses.SUBSCRIPTION_ACTIVE
      }      
    })
    builder.addCase(getSubscriptionDetails.rejected, (state, action) => {
      state.isGetSubscriptionDetailsRequest = false;
    })
    //Get plans
    builder.addCase(getPlans.pending, (state) => {
      state.isGetPlansRequest = true;
    })
    builder.addCase(getPlans.fulfilled, (state, action) => {
        state.isGetPlansRequest = false;
        state.plans = action.payload;
    })
    builder.addCase(getPlans.rejected, (state, action) => {
      state.isGetPlansRequest = false;
    })
    //Upgrade plan
    builder.addCase(upgradePlan.pending, (state, action) => {
      state.isRequestUpgradePlan = true;
      state.planToUpgrade = action.meta.arg.plan
    })
    builder.addCase(upgradePlan.fulfilled, (state, action) => {
        state.isRequestUpgradePlan = false;
        state.activeSubscription.paymentStatus = Payment_status.PENDING
    })
    builder.addCase(upgradePlan.rejected, (state, action) => {
      state.isRequestUpgradePlan = false;
    })
    //Get invoices
    builder.addCase(getInvoicesHistory.pending, (state) => {
      state.isGetInvoicesRequest = true;
    })
    builder.addCase(getInvoicesHistory.fulfilled, (state, action) => {
        state.isGetInvoicesRequest = false;
        state.invoices = action.payload.response.orders;
        state.totalInvoicesCount = action.payload.response.totalCount
    })
    builder.addCase(getInvoicesHistory.rejected, (state, action) => {
      state.isGetInvoicesRequest = false;
    })
    //Cancel plan
    builder.addCase(cancelPlan.pending, (state) => {
      state.isCancelPlanRequest = true;
    })
    builder.addCase(cancelPlan.fulfilled, (state, action) => {
      state.activeSubscription.status = PPGSubscriptionStatus.Terminated;
      state.isCancelPlanRequest = false;
    })
    builder.addCase(cancelPlan.rejected, (state, action) => {
      state.isCancelPlanRequest = false;
    })
    //Send email update payment details
    builder.addCase(sendEmailToChangePaymentDetails.pending, (state) => {
      state.isRequestToSendEmailUpdatePaymentDetails = true;
    })
    builder.addCase(sendEmailToChangePaymentDetails.fulfilled, (state, action) => {
      state.isRequestToSendEmailUpdatePaymentDetails = false;
    })
    builder.addCase(sendEmailToChangePaymentDetails.rejected, (state, action) => {
      state.isRequestToSendEmailUpdatePaymentDetails = false;
    })
  }  
  }
);

export const accountDetailsActions = accountDetailsSlice.actions;
export const accountDetailsReducer = accountDetailsSlice.reducer;