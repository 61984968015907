import { useMemo } from 'react';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useAppDispatch } from '../typedHooks';
import { adminPageSliceActions } from '../../slices/adminPageSlice/adminPageSlice';
import { createManualSubscription, createTeam, deleteAdditionalWrongInfo, deleteManualSubscription, deleteTeam, deleteUser, generatePassword, getTeamsList, getUsersList, sendEmailCredentials, sendEmailExtension, updateTeam, updateUser } from '../../slices/adminPageSlice/thunks';

const rootActions = {
  ...adminPageSliceActions,
  getUsersList,
  getTeamsList,
  sendEmailExtension,
  generatePassword,
  sendEmailCredentials,
  updateUser,
  deleteUser,
  createTeam,
  updateTeam,
  deleteTeam,
  deleteAdditionalWrongInfo,
  createManualSubscription,
  deleteManualSubscription
};

export const useAdminPageActions = () => {
  const dispatch = useAppDispatch();
  return useMemo(() => bindActionCreators(rootActions, dispatch), [dispatch]);
};