export const barColors = ['rgba(150, 45, 255, 1)', 'rgba(59, 36, 112, 1)', 'rgba(249, 87, 40, 1)', 'rgba(147, 170, 253, 1)', 'rgba(25, 31, 46, 1)'];
export const colorsRadialDiagram = ['rgba(150, 45, 255, 1)', 'rgba(249, 87, 40, 1)'];


export const generateShade = (color: string, factor: number): string => {
  const match = color.match(/rgba?\((\d+), (\d+), (\d+),? (\d*\.?\d+)?\)/);
  if (match) {
    // eslint-disable-next-line prefer-const
    let [_, r, g, b, a = "1"] = match; // a остается строкой
    r = Math.min(255, Math.floor(Number(r) * factor)).toString();
    g = Math.min(255, Math.floor(Number(g) * factor)).toString();
    b = Math.min(255, Math.floor(Number(b) * factor)).toString();
    return `rgba(${r}, ${g}, ${b}, ${a})`;
  }
  return color;
};